import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'
import { GridViewAsOfDate } from '../../../services/data/types/grid-data-view'

function usePortfolioGridCompliaceQuery(portfolioRef?: string, asOfDate?: GridViewAsOfDate | null) {
  return useQuery({
    queryKey: ['portfolio', portfolioRef, 'grid', 'compliance', asOfDate],
    queryFn: () => {
      if (portfolioRef) {
        return api.getPortfolioGridCompliance(portfolioRef, asOfDate)
      }
    },
    keepPreviousData: true,
    enabled: !!portfolioRef,
  })
}

export default usePortfolioGridCompliaceQuery
