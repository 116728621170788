import { Box, Stack } from '@mui/system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../../components/fields/numeric-field'
import { TradeDirectionField } from '../../../../components/fields/trade-direction-field'
import { PriceUpPayload, StaticDataFields } from '../../../../services/data/types/pricing'
import { Leg, TradeDirection } from '../../../../services/data/types/trade-ticket'
import { formatNaiveDate } from '../../../../utils/dates'
import { defaultInputDecimalPlaces, priceInputDecimalPlaces } from '../../../../utils/numbers'
import { CurrencyAdorment, PercentAdorment } from './shared/fields/adorments'
import BooleanSelector from './shared/fields/boolean-selector'
import DateSelector from './shared/fields/date-selector'
import { ProfileOperator } from './shared/profile-operator'
import { ProfileRow } from './shared/profile-row'

type TrsProfileProps = {
  leg: Leg
  staticDataFields: StaticDataFields
  isTradeConfirmation: boolean
  onUpdateLeg: (leg: Leg) => void
  onUpdatePricing: (payload: PriceUpPayload) => void
  onTradeDirectionChange: (tradeDirection: TradeDirection) => void
}

export function TrsProfile(props: TrsProfileProps) {
  const { leg, staticDataFields, isTradeConfirmation, onUpdateLeg, onUpdatePricing, onTradeDirectionChange } = props

  const { t } = useTranslation('tradeTicket')

  useEffect(() => {
    updatePrices()
  }, [leg.settlementDate?.getTime(), leg.isFullyFunded])

  function updatePrices() {
    if (!leg.tradeAssetType || !leg.notional || !leg.price || !leg.haircut || !leg.inceptionFx) {
      return
    }

    const payload: PriceUpPayload = {
      asset_type: leg.tradeAssetType.asset_type_tag,
      quantity_type: 'notional',
      quantity: Number(leg.notional),
      price: Number(leg.price),
      spread_bps: undefined,
      consideration: undefined,
      static_data: {},
      proposed_terms: {
        accrued_interest: { Float: Number(leg.accruedInterest || '0') },
        haircut: { Float: Number(leg.haircut) / 100 },
        inception_fx: { Float: Number(leg.inceptionFx) },
        is_fully_funded: { Boolean: leg.isFullyFunded },
      },
    }

    if (leg.sinkFactor) {
      payload.proposed_terms.factor = { Float: Number(leg.sinkFactor) / 100 }
    }

    if (leg.settlementDate) {
      payload.proposed_terms.settlement_date = { NaiveDate: formatNaiveDate(leg.settlementDate) }
    }

    onUpdatePricing(payload)
  }

  return (
    <Stack gap={3}>
      <ProfileRow>
        <TradeDirectionField
          value={leg.tradeDirection}
          disabled={isTradeConfirmation}
          onChange={onTradeDirectionChange}
          sx={{ maxWidth: '150px', mr: 2 }}
        />

        <ProfileOperator operator="(" />
        <NumericField
          required
          fixedLabel
          name="notional"
          label={t('common:notional')}
          value={leg.notional}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={(notional) => onUpdateLeg({ ...leg, notional })}
          onBlur={updatePrices}
          onEnterDown={updatePrices}
        />
        <ProfileOperator operator="x" />
        <NumericField
          fixedLabel
          name="sinkFactor"
          label={t('common:sink_factor')}
          value={leg.sinkFactor}
          decimalPlaces={priceInputDecimalPlaces}
          onValueChange={(sinkFactor) => onUpdateLeg({ ...leg, sinkFactor })}
          onBlur={updatePrices}
          onEnterDown={updatePrices}
          endAdornment={<PercentAdorment />}
        />
        <ProfileOperator operator="@" />
        <NumericField
          required
          fixedLabel
          name="clean_price"
          label={t('common:clean_price')}
          value={leg.price}
          decimalPlaces={priceInputDecimalPlaces}
          onValueChange={(price) => onUpdateLeg({ ...leg, price })}
          onBlur={updatePrices}
          onEnterDown={updatePrices}
          endAdornment={<PercentAdorment />}
        />
        <ProfileOperator operator=") +" />
        <NumericField
          required
          fixedLabel
          name="accruedInterest"
          label={t('common:accrued_interest')}
          value={leg.accruedInterest}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={(accruedInterest) => onUpdateLeg({ ...leg, accruedInterest })}
          onBlur={updatePrices}
          onEnterDown={updatePrices}
        />
      </ProfileRow>

      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator operator="x" />
        <NumericField
          required
          fixedLabel
          name="haircut"
          label={t('common:haircut')}
          value={leg.haircut}
          decimalPlaces={priceInputDecimalPlaces}
          onValueChange={(haircut) => onUpdateLeg({ ...leg, haircut })}
          onBlur={updatePrices}
          onEnterDown={updatePrices}
          endAdornment={<PercentAdorment />}
        />
        <ProfileOperator operator="=" />
        <NumericField
          disabled
          fixedLabel
          name="funding_amount_lc"
          label={t('common:funding_amount_lc')}
          value={leg.fundingAmountLc}
          decimalPlaces={defaultInputDecimalPlaces}
        />
      </ProfileRow>

      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator operator="/" />
        <NumericField
          required
          fixedLabel
          name="inception_fx"
          label={t('common:inception_fx')}
          value={leg.inceptionFx}
          decimalPlaces={priceInputDecimalPlaces}
          onValueChange={(inceptionFx) => onUpdateLeg({ ...leg, inceptionFx })}
          onBlur={updatePrices}
          onEnterDown={updatePrices}
        />
        <ProfileOperator operator="=" />
        <NumericField
          disabled
          fixedLabel
          name="trs_funding_amount"
          label={t('common:trs_funding_amount')}
          value={leg.trsFundingAmount}
          decimalPlaces={defaultInputDecimalPlaces}
          endAdornment={<CurrencyAdorment symbol={staticDataFields.assetCurrencySymbol} />}
        />
      </ProfileRow>

      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator />
        <DateSelector
          label={t('common:settlement_date')}
          value={leg.settlementDate}
          onChange={(settlementDate) => onUpdateLeg({ ...leg, settlementDate })}
          onBlur={updatePrices}
          onEnterDown={updatePrices}
        />
        <ProfileOperator />
        <BooleanSelector
          label={t('common:is_fully_funded')}
          value={leg.isFullyFunded}
          onChange={(isFullyFunded) => onUpdateLeg({ ...leg, isFullyFunded })}
        />
      </ProfileRow>
    </Stack>
  )
}
