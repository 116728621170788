import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import { GridViewAsOfDate } from '../../../services/data/types/grid-data-view'
import { CreateWhatIfPayload, WhatIf } from '../../../services/data/types/what-if'

function useCreateWhatIfMutation() {
  return useMutation<AxiosResponse<WhatIf>, AxiosError, MutationFnParams>({
    mutationFn: ({ portfolioRef, payload, datasetRef, asOfDate }) =>
      api.createWhatIf(portfolioRef, payload, datasetRef, asOfDate),
  })
}

type MutationFnParams = {
  portfolioRef: string
  payload: CreateWhatIfPayload
  datasetRef?: string | null
  asOfDate?: GridViewAsOfDate | null
}

export default useCreateWhatIfMutation
