import { Autocomplete, Dialog, Divider, TextField, Typography } from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import { ModalDetailRow } from '../../../components/modal-detail-row'
import ModalTitle from '../../../components/modal-title'
import { PortfolioHeader } from '../../../services/data/types/portfolio'
import { ReconRecord, ReconRegularMatch } from '../../../services/data/types/reconciliation'
import { formatFloat } from '../../../utils/numbers'

type PaymentForRecordModalProps = {
  open: boolean
  record: ReconRecord | null
  portfolios: PortfolioHeader[]
  accountPortfolioRef: string | null | undefined
  error: string | null
  onCreate: (match: ReconRegularMatch) => void
  onClose: () => void
}

function PaymentForRecordModal(props: PaymentForRecordModalProps) {
  const { open, record, portfolios, accountPortfolioRef, error, onCreate, onClose } = props

  const { t } = useTranslation('bankAccounts')

  const [portfolio, setPortfolio] = useState<PortfolioHeader | null>(null)
  const [description, setDescription] = useState('')

  const disablePortfolioChange = !!accountPortfolioRef && !!portfolio && portfolio.portfolio_ref === accountPortfolioRef
  const canSave = !!record && !!description.trim() && !!portfolio

  useEffect(() => {
    if (open && accountPortfolioRef) {
      const initialPortfolio = portfolios.find((p) => p.portfolio_ref === accountPortfolioRef)
      if (initialPortfolio) {
        setPortfolio(initialPortfolio)
      }
    }
  }, [open])

  function handleSubmit(event?: FormEvent) {
    event?.preventDefault()

    if (!canSave) {
      return
    }

    const reconMatch: ReconRegularMatch = {
      record_id: record.record_id,
      txns: [
        {
          ext_segment_ref: null,
          amount: record.amount,
          portfolio_ref: portfolio.portfolio_ref,
          bank_txn_ref: null,
          cparty_id: null,
          note: description,
        },
      ],
    }

    onCreate(reconMatch)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
    >
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('payment_for_record_modal.title')} onClose={onClose} />
        <ModalContent>
          <Typography variant="subtitle1">{t('payment_for_record_modal.bank_record')}</Typography>

          <Alert severity="error" message={error} />

          <ModalDetailRow label={t('payment_for_record_modal.date')} value={record?.date} />
          <ModalDetailRow label={t('payment_for_record_modal.description')} value={record?.description} />
          <ModalDetailRow label={t('payment_for_record_modal.title')} value={formatFloat(record?.amount)} />

          <Divider />

          <Autocomplete
            value={portfolio}
            options={portfolios}
            disabled={disablePortfolioChange}
            getOptionLabel={(p) => p.portfolio_name}
            isOptionEqualToValue={(option, value) => option.portfolio_ref === value.portfolio_ref}
            onChange={(_, p) => setPortfolio(p)}
            renderInput={(props) => <TextField {...props} required label={t('common:portfolio')} />}
          />

          <TextField
            required
            autoFocus
            name="description"
            label={t('payment_for_record_modal.description')}
            value={description}
            multiline
            minRows={4}
            maxRows={4}
            onChange={(event) => setDescription(event.target.value)}
            onKeyDown={(event) => {
              if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
                handleSubmit()
              }
            }}
          />
        </ModalContent>

        <ModalActions
          confirmLabel={t('payment_for_record_modal.confirm_label')}
          confirmDisabled={!canSave}
          onCancel={onClose}
        />
      </form>
    </Dialog>
  )
}

export default PaymentForRecordModal
