import { ReconFilterType } from '../../../modules/bank-accounts/data/reconciliation-filters'
import { formatFloat } from '../../../utils/numbers'
import { DatapointValue } from './datapoint'
import { GridAlert } from './grid-data'

export type RecordType = 'opening_balance' | 'regular' | 'net_zero'

export type ReconGridResponse = {
  account_id: number
  account_name: string
  currency: string
  portfolio_ref: string | null

  records: Array<ReconRecord>
  txns: Array<ReconTxn>
  matches: Array<ReconRegularMatch>
  unreconciled_bank_txns: Array<ReconUnreconciledBankTxn>
}

export type ReconRecord = {
  record_id: number
  account_id: number
  date: string
  description: string
  amount: number
  amount_to_reconcile: number
  amount_reconciled: number
  record_type: RecordType
  is_reconciled: boolean
  txns: Array<ReconTxn>
}

export type ReconTxn = {
  txn_ref: string
  txn_datetime: string
  segment_ref: string
  ext_segment_ref: string
  asset_type: string | null
  asset_type_name: string | null
  settlement_date: string
  quantity: number
  quantity_settled: number
  description: string
  portfolio_ref: string
  portfolio_name: string
  cparty_id: number | null
  cparty_name: string | null
  is_bank_txn: boolean
  bank_txns: Array<ReconBankTxn>
}

export type ReconBankTxn = {
  txn_ref: string
  txn_datetime: string
  segment_ref: string
  ext_segment_ref: string
  amount: number
  description: string | null
  is_reconciled: boolean
}

export type ReconUnreconciledBankTxn = {
  bank_txn_ref: string
  bank_datetime: string
  bank_amount: number
  txn_ref: string
  txn_datetime: string
  txn_amount: number
  segment_ref: string
  asset_type: string | null
  asset_type_name: string | null
  description: string
  portfolio_ref: string
  portfolio_name: string
  cparty_id: number | null
  cparty_name: string | null
}

export type ReconRegularMatch = {
  record_id: number
  txns: Array<ReconMatchTxn>
}

export type ReconNetZeroMatch = {
  record_date: string
  txns: Array<ReconMatchTxn>
}

export type ReconNetZeroMatchLocal = ReconNetZeroMatch & {
  // this is a local-only record id, it uses a negative number
  record_id: number
  account_id: number
}

export type ReconMatch = ReconRegularMatch | ReconNetZeroMatchLocal

export type ReconMatchTxn = {
  // None when creating a custodian txn for a single account record
  ext_segment_ref: string | null
  amount: number
  portfolio_ref: string
  // present when matching a cparty settlement, or when custodian txns already exists
  bank_txn_ref: string | null
  // present when matching a cparty settlement
  cparty_id: number | null
  // required when custodian txn (new or existing)
  note: string | null
}

export type MatchStatus = 'reconciled' | 'reconciled_err' | 'full_match' | 'partial_match' | 'no_match'

export type ReconcileTxnsPayload = {
  matches: Array<ReconRegularMatch>
  net_zero_matches: Array<ReconNetZeroMatch>
}

export type RecordsUploadOptionsResponse = {
  related_entities: Array<RecordUploadRelatedEntity>
}

export type RecordUploadRelatedEntity = {
  entity_id: number
  entity_name: string
  has_config: boolean
  accounts: Array<RecordUploadAccount>
}

export type RecordUploadAccount = {
  account_id: number
  account_name: string
  has_config: boolean
}

export type RecordColumnOption = {
  tag: string
  name: string
  index: number | null
}

export type RecordsUploadInspectResponse = {
  header_row: number
  skip_after_header_rows: number
  skip_last_rows: number
  columns: Array<RecordColumnOption>
  headings: Array<string>
  rows: Array<Array<string>>
}

export type RecordsUploadVerifyResponse = {
  valid: boolean
  errors: string | null
}

export type RecordsUploadCommitResponse = {
  commited: boolean
  errors: string | null
}

export type BankRecordsUploadParams = {
  headerRow: number
  skipAfterHeaderRows: number
  skipLastRows: number
  columns: Array<string | null>
  accountId: number | null
}

//
// Local Reconciliation Types
//

export type ReconRecordLocal = ReconRecord & {
  local_match_status: MatchStatus
}

export type ReconTxnLocal = ReconTxn & {
  local_new_quantity_settled: number
  local_total_quantity_settled: number
  local_quantity_to_settle: number
  local_settlement_percent: number
  local_match_status: MatchStatus
  local_has_matches: boolean
}

export function reconRecordLocal(record: ReconRecord): ReconRecordLocal {
  const local: ReconRecordLocal = {
    ...record,
    local_match_status: 'no_match',
  }

  if (record.is_reconciled) {
    if (formatFloat(record.amount) === formatFloat(record.amount_reconciled)) {
      local.local_match_status = 'reconciled'
    } else {
      local.local_match_status = 'reconciled_err'
    }
  }

  return local
}

export function reconTxnLocal(txn: ReconTxn): ReconTxnLocal {
  const local: ReconTxnLocal = {
    ...txn,
    local_new_quantity_settled: 0,
    local_total_quantity_settled: txn.quantity_settled,
    local_quantity_to_settle: txn.quantity - txn.quantity_settled,
    local_settlement_percent: txn.quantity !== 0 ? txn.quantity_settled / txn.quantity : 0,
    local_match_status: 'no_match',
    local_has_matches: false,
  }
  return local
}

export type ReconGrid = {
  account_name: string
  currency: string
  headings: Array<ReconGridHeading>
  rows: Array<ReconGridRow>
}

export type ReconGridHeading = {
  key: ReconGridHeadingKey
  title: string
  pinned?: true
  hide?: boolean
  width?: number
  initialWidth?: number
  filterType?: ReconFilterType
}

export type ReconGridHeadingKey =
  | 'action'
  | 'record_date'
  | 'record_description'
  | 'record_amount'
  | 'bank_balance'
  | 'record_amount_reconciled'
  | 'record_amount_to_reconcile'
  | 'record_reconciliation_status'
  | 'match_status'
  | 'arc_amount'
  | 'arc_balance'
  | 'txn_datetime'
  | 'txn_settlement_date'
  | 'txn_segment_ref'
  | 'txn_description'
  | 'txn_asset_type'
  | 'txn_quantity'
  | 'txn_quantity_to_settle'
  | 'txn_counterparty'
  | 'txn_portfolio'
  | 'txn_settlement_percent'

export type ReconGridRow = {
  rowKey: string
  sortValue: string
  isBankGrid: boolean
  mergeRow: boolean
  rowSpan: number
  record: ReconRecordLocal | null
  txn: ReconTxnLocal | null
  rogueTxn: ReconUnreconciledBankTxn | null
  selected: boolean
  showSelect: boolean
  bankBalance: number
  arcfinaAmount: number
  arcfinaBalance: number
  cells: Array<ReconGridCell>
}

export type ReconGridCell = {
  headingKey: ReconGridHeadingKey
  value: DatapointValue
  color?: string
  alert?: GridAlert
  leftDivider?: boolean
}
