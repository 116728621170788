import { Box, Stack } from '@mui/system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../../components/fields/numeric-field'
import { TradeDirectionField } from '../../../../components/fields/trade-direction-field'
import { PriceUpPayload, StaticDataFields } from '../../../../services/data/types/pricing'
import { Leg, TradeDirection } from '../../../../services/data/types/trade-ticket'
import { formatNaiveDate } from '../../../../utils/dates'
import { defaultInputDecimalPlaces, priceInputDecimalPlaces } from '../../../../utils/numbers'
import { CurrencyAdorment, PercentAdorment } from './shared/fields/adorments'
import DateSelector from './shared/fields/date-selector'
import { ProfileOperator } from './shared/profile-operator'
import { ProfileRow } from './shared/profile-row'

type StandardBondProfileProps = {
  leg: Leg
  staticDataFields: StaticDataFields
  isTradeConfirmation: boolean
  onUpdateLeg: (leg: Leg) => void
  onUpdatePricing: (payload: PriceUpPayload) => void
  onTradeDirectionChange: (tradeDirection: TradeDirection) => void
}

export function StandardBondProfile(props: StandardBondProfileProps) {
  const { leg, staticDataFields, isTradeConfirmation, onUpdateLeg, onUpdatePricing, onTradeDirectionChange } = props

  const { t } = useTranslation('tradeTicket')

  useEffect(() => {
    priceConsideration()
  }, [leg.tradeProfile, staticDataFields.parValue])

  function priceConsideration() {
    const payload = createPricingPayload()

    if (!payload || !leg.notional || !leg.price) {
      return
    }

    payload.quantity = Number(leg.notional)
    payload.price = Number(leg.price)

    onUpdatePricing(payload)
  }

  function priceNotional() {
    const payload = createPricingPayload()

    if (!payload || !leg.price || !leg.value) {
      return
    }

    payload.price = Number(leg.price)
    payload.consideration = Number(leg.value)

    onUpdatePricing(payload)
  }

  function createPricingPayload() {
    if (!leg.tradeAssetType || !leg.accruedInterest) {
      return
    }

    const payload: PriceUpPayload = {
      asset_type: leg.tradeAssetType.asset_type_tag,
      quantity_type: 'notional',
      quantity: undefined,
      price: undefined,
      spread_bps: undefined,
      consideration: undefined,
      static_data: {},
      proposed_terms: {
        accrued_interest: { Float: Number(leg.accruedInterest) },
      },
    }

    if (staticDataFields.parValue !== null) {
      payload.static_data.par_value = { Float: staticDataFields.parValue }
    }

    if (leg.sinkFactor) {
      payload.proposed_terms.factor = { Float: Number(leg.sinkFactor) / 100 }
    }

    if (leg.settlementDate) {
      payload.proposed_terms.settlement_date = { NaiveDate: formatNaiveDate(leg.settlementDate) }
    }

    return payload
  }

  return (
    <Stack gap={3}>
      <ProfileRow>
        <TradeDirectionField
          value={leg.tradeDirection}
          disabled={isTradeConfirmation}
          onChange={onTradeDirectionChange}
          sx={{ maxWidth: '150px', mr: 2 }}
        />

        <ProfileOperator operator="(" />
        <NumericField
          required
          fixedLabel
          name="notional"
          label={t('common:notional')}
          value={leg.notional}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={(notional) => onUpdateLeg({ ...leg, notional })}
          onBlur={priceConsideration}
          onEnterDown={priceConsideration}
          endAdornment={<CurrencyAdorment symbol={staticDataFields.assetCurrencySymbol} />}
        />
        <ProfileOperator operator="x" />
        <NumericField
          fixedLabel
          name="sinkFactor"
          label={t('common:sink_factor')}
          value={leg.sinkFactor}
          decimalPlaces={priceInputDecimalPlaces}
          onValueChange={(sinkFactor) => onUpdateLeg({ ...leg, sinkFactor })}
          onBlur={priceConsideration}
          onEnterDown={priceConsideration}
          endAdornment={<PercentAdorment />}
        />
        <ProfileOperator operator="x" />
        <NumericField
          required
          fixedLabel
          name="price"
          label={t('common:clean_price')}
          value={leg.price}
          decimalPlaces={priceInputDecimalPlaces}
          onValueChange={(price) => onUpdateLeg({ ...leg, price })}
          onBlur={priceConsideration}
          onEnterDown={priceConsideration}
          endAdornment={<PercentAdorment />}
        />
        <ProfileOperator operator=")" />
      </ProfileRow>
      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator operator="+" />
        <NumericField
          required
          fixedLabel
          name="accruedInterest"
          label={t('common:accrued_interest')}
          value={leg.accruedInterest}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={(accruedInterest) => onUpdateLeg({ ...leg, accruedInterest })}
          onBlur={priceConsideration}
          onEnterDown={priceConsideration}
          endAdornment={<CurrencyAdorment symbol={staticDataFields.assetCurrencySymbol} />}
        />
        <ProfileOperator operator="=" />
        <NumericField
          required
          fixedLabel
          name="value"
          label={t('common:consideration')}
          value={leg.value}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={(value) => onUpdateLeg({ ...leg, value })}
          onBlur={priceNotional}
          onEnterDown={priceNotional}
          endAdornment={<CurrencyAdorment symbol={staticDataFields.assetCurrencySymbol} />}
        />
        <ProfileOperator />
        <Box />
        <ProfileOperator />
      </ProfileRow>
      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator />
        <DateSelector
          required
          label={t('common:settlement_date')}
          value={leg.settlementDate}
          onChange={(settlementDate) => onUpdateLeg({ ...leg, settlementDate })}
        />
        <ProfileOperator />
        <Box />
        <ProfileOperator />
        <Box />
      </ProfileRow>
    </Stack>
  )
}
