import { i18n } from '../../../i18n'
import { isValidNaiveDate, NaiveDate } from '../../../utils/dates'
import { ExtractUnion } from '../../../utils/types'
import { FilterValue } from '../filter-parsing'
import { UamPanelMode } from './grid-panels'
import { Group } from './group'
import { ViewType } from './view'

export type GridDataView = {
  view_ref: string
  view_name: string
  can_modify: boolean
  dataset_ref: string
  view_type: ViewType
  group_owner: Group
  view_options: GridView
}

export type GridDataViewSortBy = {
  column: string
  direction: 'asc' | 'dsc'
}

export type GridDataViewFilters = {
  [datapointRef: string]: {
    operator: GridDataViewFilterOperator
    values: FilterValue[]
  }
}

export const filterOperators = [
  'eq',
  'lt',
  'lte',
  'gt',
  'gte',
  'btwn',
  'contains',
  'today',
  'yesterday',
  'thismonth',
  'lastmonth',
  'lastxdays',
  'nextxdaysinc',
  'nextxdaysexc',
] as const

export type GridDataViewFilterOperator = (typeof filterOperators)[number]

export type StringOperators = ExtractUnion<GridDataViewFilterOperator, 'contains'>

export type NumberOperators = ExtractUnion<GridDataViewFilterOperator, 'eq' | 'lt' | 'lte' | 'gt' | 'gte' | 'btwn'>

export type DateOperators = ExtractUnion<
  GridDataViewFilterOperator,
  | 'eq'
  | 'lt'
  | 'gt'
  | 'btwn'
  | 'today'
  | 'yesterday'
  | 'thismonth'
  | 'lastmonth'
  | 'lastxdays'
  | 'nextxdaysinc'
  | 'nextxdaysexc'
>

export type GridDataViewCreateBody = {
  view_name: string
  dataset_ref: string
  view_type: ViewType
  view_options: GridView
}

export type GridDataViewUpdateBody = {
  view_name?: string
  owner_group_ref?: string
  view_options?: GridView
}

export type GridView = {
  aggregation: string[]
  filters: GridDataViewFilters | null
  columns: GridViewColumn[] | null
  panel_mode: UamPanelMode | null
  sort_by: GridDataViewSortBy | null
  asset_types: string[] | null
  as_of_date: GridViewAsOfDate | null
}

export type GridViewColumn = {
  datapoint_ref: string
  name?: string
  position: GridViewColumnPosition
  width?: number
  decimal_places?: number
  display_raw?: true
}

export type GridViewColumnPosition = 'm' | 'h' | 'p'

// Reproduces the backend type
export type GridViewAsOfDate = GridViewAsOfDateFixed | NaiveDate
export type GridViewAsOfDateFixed = (typeof asOfDateFixedTypes)[number]
const asOfDateFixedTypes = ['current', 'last_business_day', 'end_of_month', 'end_of_year'] as const

// Local version which makes more sense
export type GridViewAsOfDateLocal = (typeof asOfDateTypes)[number]
export const asOfDateTypes = [...asOfDateFixedTypes, 'specific_date'] as const

export function convertGridViewAsOfDateToLocalType(asOfDate: GridViewAsOfDate): GridViewAsOfDateLocal {
  if (isSpecificAsOfDate(asOfDate)) {
    return 'specific_date'
  } else if (isFixedAsOfDate(asOfDate)) {
    return asOfDate
  }
  throw new Error(`Invalid GridViewAsOfDate [${asOfDate}]`)
}

// Returns true when asOfDate is an `specific_date` and a valid NaiveDate.
export function isSpecificAsOfDate(asOfDate: GridViewAsOfDate): boolean {
  if (isFixedAsOfDate(asOfDate)) {
    return false
  }
  if (isValidNaiveDate(asOfDate)) {
    return true
  }
  throw new Error(`Invalid GridViewAsOfDate 'specific_date' [${asOfDate}]`)
}

function isFixedAsOfDate(asOfDate: GridViewAsOfDate): asOfDate is GridViewAsOfDateFixed {
  return asOfDateFixedTypes.includes(asOfDate as any)
}

export function getAsOfDateLabel(asOfDate: GridViewAsOfDate | GridViewAsOfDateLocal | null | undefined) {
  // current comes as null from the backend
  if (!asOfDate) {
    return i18n.t('portfolio:current')
  }

  // backend and local fixed types
  if (isFixedAsOfDate(asOfDate)) {
    const labels: { [key in GridViewAsOfDateFixed]: string } = {
      current: i18n.t('portfolio:current'),
      last_business_day: i18n.t('portfolio:last_business_day'),
      end_of_month: i18n.t('portfolio:end_of_month'),
      end_of_year: i18n.t('portfolio:end_of_year'),
    }
    return labels[asOfDate]
  }

  // when local type
  if (asOfDate === 'specific_date') {
    return i18n.t('portfolio:specific_date')
  }

  // when naive date on backend type
  return asOfDate
}
