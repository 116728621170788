import { addDays, lastDayOfMonth, setDate, subDays, subMonths } from 'date-fns'
import { useCallback, useState } from 'react'
import {
  DateOperators,
  GridDataViewFilterOperator,
  NumberOperators,
  StringOperators,
} from '../../../services/data/types/grid-data-view'
import {
  ReconGridHeadingKey,
  ReconRecordLocal,
  ReconTxnLocal,
  ReconUnreconciledBankTxn,
} from '../../../services/data/types/reconciliation'
import { formatNaiveDate } from '../../../utils/dates'
import { roundDecimals } from '../../../utils/numbers'

export type ReconFilterType =
  | { type: 'string' }
  | { type: 'number' }
  | { type: 'percent' }
  | { type: 'date' }
  | { type: 'select'; options: ReconFilterOption[] }

export type ReconFilterOption = {
  value: string
  title: string
}

export type ReconFilterState = {
  [key in ReconGridHeadingKey]?: ReconFilterStateValue
}

export type ReconFilterStateValue = {
  operator: GridDataViewFilterOperator
  values: (string | number)[]
}

export function useReconFilters() {
  const [filters, setFilters] = useState<ReconFilterState>({})

  const setFilter = useCallback(
    (key: ReconGridHeadingKey, filter: ReconFilterStateValue) => {
      setFilters((prev) => ({ ...prev, [key]: filter }))
    },
    [setFilters]
  )

  const removeFilter = useCallback(
    (key: ReconGridHeadingKey) => {
      setFilters((prev) => {
        const next = { ...prev }
        delete next[key]
        return next
      })
    },
    [setFilters]
  )

  const clear = useCallback(() => {
    setFilters({})
  }, [setFilters])

  return { filters, setFilter, removeFilter, clear }
}

export function filterBankRow(params: {
  filters: ReconFilterState
  record: ReconRecordLocal
  txn: ReconTxnLocal | null
  bankBalance: number
  arcfinaAmount: number
  arcfinaBalance: number
  isReconciling: boolean
}): boolean {
  for (const [key, filter] of Object.entries(params.filters)) {
    const headingKey = key as ReconGridHeadingKey
    let filterOut = false
    const netZeroRecord = params.record.record_type === 'net_zero'

    if (headingKey === 'record_date') {
      filterOut = netZeroRecord || filterDate(params.record.date, filter)
    } else if (headingKey === 'record_description') {
      filterOut = netZeroRecord || filterString(params.record.description, filter)
    } else if (headingKey === 'record_amount') {
      filterOut = netZeroRecord || filterNumber(params.record.amount, filter)
    } else if (headingKey === 'bank_balance') {
      filterOut = netZeroRecord || filterNumber(params.bankBalance, filter)
    } else if (headingKey === 'arc_amount') {
      filterOut = filterNumber(params.arcfinaAmount, filter)
    } else if (headingKey === 'arc_balance') {
      filterOut = filterNumber(params.arcfinaBalance, filter)
    } else if (headingKey === 'match_status') {
      filterOut = filterOption(params.record.local_match_status, filter)
    } else if (headingKey === 'record_reconciliation_status') {
      filterOut = filterOption(params.record.local_match_status, filter)
    } else if (headingKey === 'txn_settlement_date') {
      filterOut = !params.txn || filterDate(params.txn.settlement_date, filter)
    } else if (headingKey === 'txn_datetime') {
      filterOut = !params.txn || filterDate(params.txn.txn_datetime, filter)
    } else if (headingKey === 'txn_segment_ref') {
      filterOut = !params.txn || filterString(params.txn.segment_ref, filter)
    } else if (headingKey === 'txn_description') {
      filterOut = !params.txn || filterString(params.txn.description, filter)
    } else if (headingKey === 'txn_asset_type') {
      filterOut = !params.txn || filterOption(params.txn.asset_type || '', filter)
    } else if (headingKey === 'txn_portfolio') {
      filterOut = !params.txn || filterOption(params.txn.portfolio_ref, filter)
    } else if (headingKey === 'txn_counterparty') {
      filterOut = !params.txn || filterOption(String(params.txn.cparty_id), filter)
    } else if (headingKey === 'txn_quantity') {
      filterOut = !params.txn || filterNumber(params.txn.quantity, filter)
    } else if (headingKey === 'txn_quantity_to_settle') {
      filterOut = !params.txn || filterNumber(params.txn.local_quantity_to_settle, filter)
    } else if (headingKey === 'txn_settlement_percent') {
      filterOut = !params.txn || filterNumber(params.txn.local_settlement_percent, filter)
    }

    if (filterOut) {
      return true
    }
  }
  return false
}

export function filterUnreconciledBankRow(params: {
  filters: ReconFilterState
  txn: ReconUnreconciledBankTxn
  bankBalance: number
  arcfinaAmount: number
  arcfinaBalance: number
}): boolean {
  for (const [key, filter] of Object.entries(params.filters)) {
    const headingKey = key as ReconGridHeadingKey
    let filterOut = false

    if (headingKey === 'arc_amount') {
      filterOut = filterNumber(params.arcfinaAmount, filter)
    } else if (headingKey === 'arc_balance') {
      filterOut = filterNumber(params.arcfinaBalance, filter)
    } else if (headingKey === 'txn_settlement_date') {
      filterOut = filterDate(params.txn.bank_datetime, filter)
    } else if (headingKey === 'txn_datetime') {
      filterOut = filterDate(params.txn.txn_datetime, filter)
    } else if (headingKey === 'txn_segment_ref') {
      filterOut = filterString(params.txn.segment_ref, filter)
    } else if (headingKey === 'txn_description') {
      filterOut = filterString(params.txn.description, filter)
    } else if (headingKey === 'txn_asset_type') {
      filterOut = filterOption(params.txn.asset_type || '', filter)
    } else if (headingKey === 'txn_portfolio') {
      filterOut = filterOption(params.txn.portfolio_ref, filter)
    } else if (headingKey === 'txn_counterparty') {
      filterOut = filterOption(String(params.txn.cparty_id), filter)
    } else if (headingKey === 'txn_quantity') {
      filterOut = filterNumber(params.txn.txn_amount, filter)
    } else {
      // filter out all other columns
      filterOut = true
    }

    if (filterOut) {
      return true
    }
  }
  return false
}

export function filterLedgerRow(params: { filters: ReconFilterState; txn: ReconTxnLocal }): boolean {
  for (const [key, filter] of Object.entries(params.filters)) {
    const headingKey = key as ReconGridHeadingKey
    let filterOut = false

    if (headingKey === 'txn_settlement_date') {
      filterOut = filterDate(params.txn.settlement_date, filter)
    } else if (headingKey === 'txn_datetime') {
      filterOut = filterDate(params.txn.txn_datetime, filter)
    } else if (headingKey === 'txn_segment_ref') {
      filterOut = filterString(params.txn.segment_ref, filter)
    } else if (headingKey === 'txn_description') {
      filterOut = filterString(params.txn.description, filter)
    } else if (headingKey === 'txn_asset_type') {
      filterOut = filterOption(params.txn.asset_type || '', filter)
    } else if (headingKey === 'txn_portfolio') {
      filterOut = filterOption(params.txn.portfolio_ref, filter)
    } else if (headingKey === 'txn_counterparty') {
      filterOut = filterOption(String(params.txn.cparty_id), filter)
    } else if (headingKey === 'txn_quantity') {
      filterOut = filterNumber(params.txn.quantity, filter)
    } else if (headingKey === 'txn_quantity_to_settle') {
      filterOut = filterNumber(params.txn.local_quantity_to_settle, filter)
    } else if (headingKey === 'txn_settlement_percent') {
      filterOut = filterNumber(params.txn.local_settlement_percent, filter)
    }

    if (filterOut) {
      return true
    }
  }
  return false
}

function filterString(str: string, filter: ReconFilterStateValue): boolean {
  const operation = stringOperations[filter.operator as StringOperators]
  if (!operation) {
    throw Error(`Invalid operator [${filter.operator}] for strings`)
  }
  const values = filter.values.map((v) => String(v))
  return !operation(str, values)
}

function filterNumber(num: number, filter: ReconFilterStateValue): boolean {
  const operation = numberOperations[filter.operator as NumberOperators]
  if (!operation) {
    throw Error(`Invalid operator [${filter.operator}] for numbers`)
  }
  const values = filter.values.map((v) => Number(v))
  // round decimals so the user can type what the see on the grid (not a perfect solution)
  return !operation(roundDecimals(num, 4), values)
}

function filterDate(date: string, filter: ReconFilterStateValue): boolean {
  const operation = dateOperations[filter.operator as DateOperators]
  if (!operation) {
    throw Error(`Invalid operator [${filter.operator}] for dates`)
  }
  const values = filter.values.map((v) => String(v))
  return !operation(date, values)
}

function filterOption(option: string, filter: ReconFilterStateValue): boolean {
  return !filter.values.some((v) => v === option)
}

const stringOperations: {
  [key in StringOperators]: (str: string, values: string[]) => boolean
} = {
  contains: (str, values) => values.some((v) => str.toLocaleLowerCase().includes(v.toLocaleLowerCase())),
}

const numberOperations: {
  [key in NumberOperators]: (num: number, values: number[]) => boolean
} = {
  eq: (num, values) => values.some((v) => num === v),
  lt: (num, values) => values.some((v) => num < v),
  lte: (num, values) => values.some((v) => num <= v),
  gt: (num, values) => values.some((v) => num > v),
  gte: (num, values) => values.some((v) => num >= v),
  btwn: (num, values) => {
    if (values.length !== 2) {
      throw Error(`Between operator requires two values, got: ${values}`)
    }
    const v1 = values[0]!
    const v2 = values[1]!
    return v1 <= num && num <= v2
  },
}

function getNaiveDateStr(date: string): string {
  return date.substring(0, 10)
}

const dateOperations: {
  [key in DateOperators]: (date: string, values: string[]) => boolean
} = {
  eq: (date, values) => values.some((v) => getNaiveDateStr(date) === v),
  lt: (date, values) => values.some((v) => getNaiveDateStr(date) < v),
  gt: (date, values) => values.some((v) => getNaiveDateStr(date) > v),
  btwn: (date, values) => {
    if (values.length !== 2) {
      throw Error(`Between operator requires two values, got: ${values}`)
    }
    const dateStr = getNaiveDateStr(date)
    const from = values[0]!
    const to = values[1]!
    return from <= dateStr && dateStr <= to
  },
  today: (date, _) => {
    const dateStr = getNaiveDateStr(date)
    const today = formatNaiveDate(new Date())
    return dateStr === today
  },
  yesterday: (date, _) => {
    const dateStr = getNaiveDateStr(date)
    const yesterday = formatNaiveDate(subDays(new Date(), 1))
    return dateStr === yesterday
  },
  thismonth: (date, _) => {
    const dateStr = getNaiveDateStr(date)
    const today = new Date()
    const from = formatNaiveDate(setDate(today, 1))
    const to = formatNaiveDate(lastDayOfMonth(today))
    return from <= dateStr && dateStr <= to
  },
  lastmonth: (date, _) => {
    const dateStr = getNaiveDateStr(date)
    const today = new Date()
    const from = formatNaiveDate(setDate(subMonths(today, 1), 1))
    const to = formatNaiveDate(lastDayOfMonth(subMonths(today, 1)))
    return from <= dateStr && dateStr <= to
  },
  lastxdays: (date, values) => {
    if (values.length !== 1) {
      throw Error(`Last X Days operator requires one value, got: ${values}`)
    }
    const xDays = Number(values[0]!)

    const dateStr = getNaiveDateStr(date)
    const today = new Date()
    const from = formatNaiveDate(subDays(today, xDays))
    const to = formatNaiveDate(today)
    return from <= dateStr && dateStr <= to
  },
  nextxdaysinc: (date, values) => {
    if (values.length !== 1) {
      throw Error(`Next X Days Inc. operator requires one value, got: ${values}`)
    }
    const xDays = Number(values[0]!)

    const dateStr = getNaiveDateStr(date)
    const today = new Date()
    const from = formatNaiveDate(today)
    const to = formatNaiveDate(addDays(today, xDays))
    return from <= dateStr && dateStr <= to
  },
  nextxdaysexc: (date, values) => {
    if (values.length !== 1) {
      throw Error(`Next X Days Exc. operator requires one value, got: ${values}`)
    }
    const xDays = Number(values[0]!)

    const dateStr = getNaiveDateStr(date)
    const today = new Date()
    const from = formatNaiveDate(today)
    const to = formatNaiveDate(addDays(today, xDays))
    return from < dateStr && dateStr <= to
  },
}
