import { FormControlLabel, Menu, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { isValid } from 'date-fns'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FilterFormButtons from '../../../components/filters/filter-form-buttons'
import {
  asOfDateTypes,
  convertGridViewAsOfDateToLocalType,
  getAsOfDateLabel,
  GridViewAsOfDate,
  GridViewAsOfDateLocal,
  isSpecificAsOfDate,
} from '../../../services/data/types/grid-data-view'
import { dateFormat, datePlaceholder, formatNaiveDate, parseNaiveDate } from '../../../utils/dates'

type AsOfDateMenuProps = {
  anchorEl: HTMLElement | null
  currentAsOfDate: GridViewAsOfDate | null | undefined
  onChange: (asOfDate: GridViewAsOfDate | null) => void
  onClose: () => void
}

function AsOfDateMenu(props: AsOfDateMenuProps) {
  const { anchorEl, currentAsOfDate, onChange, onClose } = props

  const { t } = useTranslation('portfolio')

  const [asOfDateType, setAsOfDateType] = useState<GridViewAsOfDateLocal>('current')
  const [customDate, setCustomDate] = useState<Date | null>(null)

  const customDateFormatted = customDate ? formatNaiveDate(customDate) : null

  const isSpecificDate = asOfDateType === 'specific_date'
  const isSpecificDateSelected = isSpecificDate && !!customDate
  const isSpecificDateSame = !!currentAsOfDate && !!customDateFormatted && currentAsOfDate === customDateFormatted

  const canSubmit = !isSpecificDate || (isSpecificDateSelected && !isSpecificDateSame)

  useEffect(() => {
    if (!anchorEl) {
      setAsOfDateType('current')
      setCustomDate(null)
    }

    if (currentAsOfDate) {
      setAsOfDateType(convertGridViewAsOfDateToLocalType(currentAsOfDate))
      if (isSpecificAsOfDate(currentAsOfDate)) {
        setCustomDate(parseNaiveDate(currentAsOfDate))
      }
    }
  }, [currentAsOfDate, anchorEl])

  function handleDateTypeChange(value: GridViewAsOfDateLocal) {
    setAsOfDateType(value)
  }

  function handleDateChange(value: Date | null) {
    if (isValid(value)) {
      setCustomDate(value)
    }
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (asOfDateType === 'specific_date') {
      if (customDateFormatted) {
        onChange(customDateFormatted)
      } else {
        throw new Error('Missing specific date selected')
      }
    } else if (asOfDateType === 'current') {
      // current is treated as null by the backend
      onChange(null)
    } else {
      onChange(asOfDateType)
    }

    onClose()
  }

  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
      PaperProps={{
        sx: {
          mt: 0.5,
          width: 330,
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <Stack sx={{ px: 1, pt: 1 }}>
          <Typography variant="overline" color="gray.700" sx={{ px: 2, py: 0.5 }}>
            {t('as_of_date')}
          </Typography>
          <RadioGroup
            value={asOfDateType}
            onChange={(_, value) => handleDateTypeChange(value as GridViewAsOfDateLocal)}
            sx={{
              gap: 0.25,
              px: 2,
              '& .MuiSvgIcon-root': {
                fontSize: 16,
              },
            }}
          >
            {asOfDateTypes.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={<Typography variant="body2">{getAsOfDateLabel(option)}</Typography>}
              />
            ))}
            {isSpecificDate && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={customDate}
                  inputFormat={dateFormat}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      error={false}
                      autoComplete="off"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: datePlaceholder,
                      }}
                      sx={{ my: 2 }}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          </RadioGroup>
        </Stack>
        <FilterFormButtons clearDisabled submitDisabled={!canSubmit} onCancel={onClose} />
      </form>
    </Menu>
  )
}

export default AsOfDateMenu
