import { Dialog } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { parseDatapointFormToPayload } from '../data/datapoint-parsing'
import useCreateNewDatapointMutation from '../data/use-create-new-datapoint-mutation'
import DatapointForm, { DatapointFormValues } from './datapoint-form'

type AddNewDatapointModalProps = {
  open: boolean
  currentDatasetRef: string
  onClose: () => void
}

const initialFormValues: DatapointFormValues = {
  datapointName: '',
  variety: '',
  candidateAliasId: '',
  dataType: '',
  assetCategory: '',
  datadocType: '',
  classificationId: '',
  parentClassId: '',
  calculationId: '',
  aggregationType: '',
  weightDatapointRef: '',
  customFunctionId: '',
  aggregationOnlyAll: false,
  calculationInputs: {},
}

function AddNewDatapointModal(props: AddNewDatapointModalProps) {
  const { open, currentDatasetRef, onClose } = props
  const { t } = useTranslation('dataEngine')

  const createDatapoint = useCreateNewDatapointMutation()

  const [formValues, setFormValues] = useState(initialFormValues)

  function handleChange(name: string, value: string | boolean | DatapointFormValues['calculationInputs']) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    const createNewDatapointParams = {
      datasetRef: currentDatasetRef,
      body: parseDatapointFormToPayload(formValues),
    }

    createDatapoint.mutate(createNewDatapointParams, {
      onSuccess: () => {
        queryClient.invalidateQueries(['dataset', currentDatasetRef, 'datapoints'])
        handleClose()
      },
    })
  }

  function handleClose() {
    createDatapoint.reset()
    onClose()
    setFormValues(initialFormValues)
  }

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('datapoint_table.add_new_datapoint')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={createDatapoint.error} />
          <DatapointForm
            currentDatasetRef={currentDatasetRef}
            values={formValues}
            onChange={handleChange}
            setFormValues={setFormValues}
          />
        </ModalContent>
        <ModalActions confirmLabel={t('datapoint_table.add_datapoint')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default AddNewDatapointModal
