import { t } from 'i18next'
import { GridAlert } from '../../../services/data/types/grid-data'
import {
  MatchStatus,
  ReconGrid,
  ReconGridCell,
  ReconGridHeading,
  ReconGridResponse,
  ReconGridRow,
  ReconMatch,
  ReconRecord,
  ReconRecordLocal,
  reconRecordLocal,
  ReconTxnLocal,
  reconTxnLocal,
  ReconUnreconciledBankTxn,
} from '../../../services/data/types/reconciliation'
import {
  filterBankRow,
  filterLedgerRow,
  filterUnreconciledBankRow,
  ReconFilterOption,
  ReconFilterState,
} from './reconciliation-filters'

export type SelectedTxns = ReconTxnLocal[]

export function processGrids(
  gridResponse: ReconGridResponse | null,
  selectedRecord: ReconRecord | null,
  selectedTxns: SelectedTxns,
  matches: Array<ReconMatch>,
  bankFilters: ReconFilterState,
  ledgerFilters: ReconFilterState,
  isReconciling: boolean
): [ReconGrid | null, ReconGrid | null] {
  if (!gridResponse) {
    return [null, null]
  }

  const grid = window.structuredClone(gridResponse)

  // add dummy records from net zero matches to records list
  if (isReconciling) {
    const netZeroMatches = matches.filter((m) => 'record_date' in m)

    for (const match of netZeroMatches) {
      const totalAmt = match.txns.reduce((acc, t) => acc + t.amount, 0)

      grid.records.push({
        record_id: match.record_id,
        account_id: match.account_id,
        date: match.record_date,
        description: 'Net Zero Bank Record',
        amount: totalAmt,
        amount_to_reconcile: totalAmt,
        amount_reconciled: 0,
        record_type: 'net_zero',
        is_reconciled: false,
        txns: [],
      })
    }
  }

  const filterOptions = collectFilterOptions(grid)

  const ledgerGrid: ReconGrid | null = {
    account_name: grid.account_name,
    currency: grid.currency,
    headings: ledgerHeadings(filterOptions, isReconciling),
    rows: [],
  }

  for (const gridTxn of grid.txns) {
    const txn = reconTxnLocal(gridTxn)

    let txnExtSegmentRefs: string[] = []
    if (!txn.is_bank_txn) {
      txnExtSegmentRefs = txnExtSegmentRefs.concat(txn.bank_txns.map((bt) => bt.ext_segment_ref))
    }

    if (isReconciling) {
      for (const match of matches) {
        for (const matchTxn of match.txns) {
          if (matchTxn.ext_segment_ref && txnExtSegmentRefs.includes(matchTxn.ext_segment_ref)) {
            txn.local_has_matches = true
          }

          if (matchTxn.ext_segment_ref && matchTxn.ext_segment_ref === txn.ext_segment_ref) {
            txn.local_has_matches = true
            txn.local_new_quantity_settled += matchTxn.amount
            txn.local_total_quantity_settled += matchTxn.amount
          }
        }
      }
    }

    txn.local_quantity_to_settle -= txn.local_new_quantity_settled
    if (txn.quantity !== 0) {
      txn.local_settlement_percent = txn.local_total_quantity_settled / txn.quantity
    }

    if (txn.local_has_matches) {
      if (txn.local_quantity_to_settle === 0) {
        txn.local_match_status = 'full_match'
      } else {
        txn.local_match_status = 'partial_match'
      }
    } else {
      const isOverReconciled =
        txn.quantity_settled !== 0 &&
        ((txn.quantity < 0 && txn.quantity > txn.quantity_settled) ||
          (txn.quantity > 0 && txn.quantity < txn.quantity_settled))
      if (isOverReconciled) {
        txn.local_match_status = 'reconciled_err'
      }
    }

    const filterOut = filterLedgerRow({ filters: ledgerFilters, txn })

    if (!filterOut) {
      ledgerGrid.rows.push({
        rowKey: txn.ext_segment_ref,
        sortValue: '', // not used on ledgerGrid
        isBankGrid: false,
        mergeRow: false,
        rowSpan: 1,
        record: null,
        txn: txn,
        rogueTxn: null,
        selected: selectedTxns.some((t) => t.ext_segment_ref === txn.ext_segment_ref),
        showSelect: true,
        bankBalance: 0,
        arcfinaAmount: 0,
        arcfinaBalance: 0,
        cells: ledgerCells(txn),
      })
    }
  }

  const bankGrid: ReconGrid = {
    account_name: grid.account_name,
    currency: grid.currency,
    headings: bankHeadings(filterOptions, isReconciling),
    rows: [],
  }

  let bankBalance = 0
  let arcfinaBalance = 0

  for (const gridRecord of grid.records) {
    const record = reconRecordLocal(gridRecord)
    let recordAmountMatched = 0
    bankBalance += record.amount

    const recordMatches = isReconciling ? matches.filter((m) => m.record_id === record.record_id) : []

    // has reconciled txns
    for (const recordTxn of record.txns) {
      const txn = reconTxnLocal(recordTxn)
      const arcfinaAmount = txn.bank_txns.reduce((acc, bt) => acc + bt.amount, 0)
      arcfinaBalance += arcfinaAmount

      const filterOut = filterBankRow({
        filters: bankFilters,
        record,
        txn,
        bankBalance,
        arcfinaAmount,
        arcfinaBalance,
        isReconciling,
      })

      if (!filterOut) {
        const extSegRefs = txn.bank_txns.reduce((acc, bt) => `${acc}+${bt.ext_segment_ref}`, '')

        bankGrid.rows.push({
          rowKey: `record_${record.record_id}_${extSegRefs}`,
          sortValue: record.record_type === 'net_zero' ? txn.settlement_date : record.date,
          isBankGrid: true,
          mergeRow: false,
          rowSpan: 1,
          record: record,
          txn: txn,
          rogueTxn: null,
          selected: selectedRecord?.record_id === record.record_id,
          showSelect: record.local_match_status === 'no_match' || record.local_match_status === 'partial_match',
          bankBalance,
          arcfinaAmount,
          arcfinaBalance,
          cells: [],
        })
      }
    }

    // no reconciled txns and no matches
    if (record.txns.length === 0 && recordMatches.length === 0) {
      const filterOut = filterBankRow({
        filters: bankFilters,
        record,
        txn: null,
        bankBalance,
        arcfinaAmount: 0,
        arcfinaBalance,
        isReconciling,
      })

      if (!filterOut) {
        bankGrid.rows.push({
          rowKey: `record_${record.record_id}_unmatched`,
          sortValue: record.date, // it cannot be a net zero record
          isBankGrid: true,
          mergeRow: false,
          rowSpan: 1,
          record: record,
          txn: null,
          rogueTxn: null,
          selected: selectedRecord?.record_id === record.record_id,
          showSelect: record.local_match_status === 'no_match' || record.local_match_status === 'partial_match',
          bankBalance,
          arcfinaAmount: 0,
          arcfinaBalance,
          cells: [],
        })
      }
    }

    // has matches
    if (isReconciling && recordMatches.length > 0) {
      for (const match of recordMatches) {
        match.txns.map((matchTxn, index) => {
          const gridTxn = grid.txns.find((t) => {
            if (matchTxn.bank_txn_ref) {
              return t.bank_txns.some((bt) => bt.ext_segment_ref === matchTxn.ext_segment_ref)
            } else {
              return t.ext_segment_ref === matchTxn.ext_segment_ref
            }
          })
          const txn = gridTxn ? reconTxnLocal(gridTxn) : null

          const arcfinaAmount = matchTxn.amount
          arcfinaBalance += matchTxn.amount

          record.amount_reconciled += matchTxn.amount
          record.amount_to_reconcile -= matchTxn.amount
          recordAmountMatched += matchTxn.amount

          if (record.amount_reconciled === record.amount) {
            record.local_match_status = 'full_match'
          } else {
            record.local_match_status = 'partial_match'
          }

          const filterOut = filterBankRow({
            filters: bankFilters,
            record,
            txn,
            bankBalance,
            arcfinaAmount,
            arcfinaBalance,
            isReconciling,
          })

          if (!filterOut) {
            bankGrid.rows.push({
              rowKey: `record_${record.record_id}_${txn?.ext_segment_ref || 'newbanktxn'}#${index}_match_${matchTxn.ext_segment_ref}`,
              sortValue: record.record_type === 'net_zero' && txn ? txn.settlement_date : record.date,
              isBankGrid: true,
              mergeRow: false,
              rowSpan: 1,
              record: record,
              txn: txn,
              rogueTxn: null,
              selected: selectedRecord?.record_id === record.record_id,
              // `record.status === 'no_match' ||` needs to be here in case the logic changes
              showSelect: record.record_type !== 'net_zero' && record.local_match_status === 'partial_match',
              bankBalance,
              arcfinaAmount,
              arcfinaBalance,
              cells: [],
            })
          }
        })
      }
    }
  }

  // add unreconciled bank txns into the bank grid
  for (const txn of grid.unreconciled_bank_txns) {
    const isMatched = matches.some((m) => m.txns.some((t) => t.bank_txn_ref === txn.bank_txn_ref))
    if (isMatched) {
      continue
    }

    const arcfinaAmount = txn.bank_amount
    arcfinaBalance += txn.bank_amount

    const filterOut = filterUnreconciledBankRow({
      filters: bankFilters,
      txn,
      bankBalance,
      arcfinaAmount,
      arcfinaBalance,
    })

    if (!filterOut) {
      bankGrid.rows.push({
        rowKey: `unreconciled_${txn.bank_txn_ref}`,
        sortValue: txn.txn_datetime,
        isBankGrid: true,
        mergeRow: false,
        rowSpan: 1,
        record: null,
        txn: null,
        rogueTxn: txn,
        selected: false,
        showSelect: false,
        arcfinaAmount,
        bankBalance,
        arcfinaBalance,
        cells: [],
      })
    }
  }

  bankGrid.rows.sort((a, b) => a.sortValue.localeCompare(b.sortValue))

  // merge bank grid rows and set cells
  let prevRow: ReconGridRow | null = null
  for (const row of bankGrid.rows) {
    const mergeRow = !!row.record && !!prevRow?.record && row.record?.record_id === prevRow?.record?.record_id

    row.mergeRow = mergeRow
    if (row.rogueTxn) {
      row.cells = unreconciledBankTxnCells(row.rogueTxn, row.bankBalance, row.arcfinaAmount, row.arcfinaBalance)
    } else if (row.record) {
      row.cells = bankCells(
        row.record,
        row.txn,
        mergeRow,
        row.bankBalance,
        row.arcfinaAmount,
        row.arcfinaBalance,
        isReconciling
      )
    }

    if (mergeRow) {
      if (prevRow) {
        prevRow.rowSpan += 1
      }
    } else {
      prevRow = row
    }
  }

  return [bankGrid, ledgerGrid]
}

function bankCells(
  record: ReconRecordLocal,
  txn: ReconTxnLocal | null,
  mergeRow: boolean,
  bankBalance: number,
  arcfinaAmount: number,
  arcfinaBalance: number,
  isReconciling: boolean
): ReconGridCell[] {
  const showLedger = record.is_reconciled || isReconciling
  const netZeroRecord = record.record_type === 'net_zero'
  const showRecordDateMismatch = !mergeRow && txn && record.date !== txn.settlement_date && !netZeroRecord

  return [
    {
      headingKey: 'action',
      value: 'None',
    },
    {
      headingKey: 'record_date',
      value: mergeRow || netZeroRecord ? 'None' : { NaiveDate: record.date },
      alert: showRecordDateMismatch
        ? {
            level: 'warning',
            message: t('bankAccounts:recon_table:record_date_mismatch', { date: txn.settlement_date }),
          }
        : undefined,
    },
    {
      headingKey: 'record_description',
      value: mergeRow || netZeroRecord ? 'None' : { String: record.description },
    },
    {
      headingKey: 'record_amount',
      value: mergeRow || netZeroRecord ? 'None' : { Float: record.amount },
    },
    {
      headingKey: 'record_amount_reconciled',
      value: mergeRow || netZeroRecord ? 'None' : { Float: record.amount_reconciled },
    },
    {
      headingKey: 'record_amount_to_reconcile',
      value: mergeRow || netZeroRecord ? 'None' : { Float: record.amount_to_reconcile },
    },
    {
      headingKey: 'record_reconciliation_status',
      value: { String: getReconStatusLabel(record.local_match_status, record.is_reconciled) },
      color: getReconStatusColor(record.local_match_status, record.is_reconciled),
      alert: getReconAlert(record.local_match_status),
      leftDivider: true,
    },
    {
      headingKey: 'match_status',
      value: { String: getMatchStatusLabel(record.local_match_status) },
      color: getMatchStatusColor(record.local_match_status),
      alert: getReconAlert(record.local_match_status),
      leftDivider: true,
    },
    {
      headingKey: 'arc_amount',
      value: showLedger && txn ? { Float: arcfinaAmount } : 'None',
      leftDivider: true,
    },
    {
      headingKey: 'bank_balance',
      value: mergeRow || netZeroRecord ? 'None' : { Float: bankBalance },
    },
    {
      headingKey: 'arc_balance',
      value: showLedger && txn ? { Float: arcfinaBalance } : 'None',
    },
    // ledger part
    {
      headingKey: 'txn_settlement_date',
      value: showLedger && txn ? { NaiveDate: txn.settlement_date } : 'None',
    },
    {
      headingKey: 'txn_datetime',
      value: showLedger && txn ? { DateTime: txn.txn_datetime } : 'None',
    },
    {
      headingKey: 'txn_segment_ref',
      value: showLedger && txn ? { String: txn.segment_ref } : 'None',
    },
    {
      headingKey: 'txn_portfolio',
      value: showLedger && txn ? { String: txn.portfolio_name } : 'None',
    },
    {
      headingKey: 'txn_description',
      value: showLedger && txn ? { String: txn.description } : 'None',
    },
    {
      headingKey: 'txn_asset_type',
      value: showLedger && txn ? { String: txn.asset_type_name || '-' } : 'None',
    },
    {
      headingKey: 'txn_counterparty',
      value: showLedger && txn ? { String: txn.cparty_name || '-' } : 'None',
    },
    {
      headingKey: 'txn_quantity',
      value: showLedger && txn ? { Float: txn.quantity } : 'None',
    },
    {
      headingKey: 'txn_quantity_to_settle',
      value: showLedger && txn ? { Float: txn.local_quantity_to_settle } : 'None',
    },
    {
      headingKey: 'txn_settlement_percent',
      value: showLedger && txn ? { Percent: txn.local_settlement_percent } : 'None',
      color: getSettlementPercentColor(txn),
    },
  ]
}

function ledgerCells(txn: ReconTxnLocal): ReconGridCell[] {
  return [
    {
      headingKey: 'action',
      value: 'None',
    },
    {
      headingKey: 'txn_settlement_date',
      value: { NaiveDate: txn.settlement_date },
    },
    {
      headingKey: 'txn_datetime',
      value: { DateTime: txn.txn_datetime },
    },
    {
      headingKey: 'txn_segment_ref',
      value: { String: txn.segment_ref },
    },
    {
      headingKey: 'txn_portfolio',
      value: { String: txn.portfolio_name },
    },
    {
      headingKey: 'txn_description',
      value: { String: txn.description },
    },
    {
      headingKey: 'txn_asset_type',
      value: { String: txn.asset_type_name || '-' },
    },
    {
      headingKey: 'txn_counterparty',
      value: { String: txn.cparty_name || '-' },
    },
    {
      headingKey: 'txn_quantity',
      value: { Float: txn.quantity },
    },
    {
      headingKey: 'txn_quantity_to_settle',
      value: { Float: txn.local_quantity_to_settle },
    },
    {
      headingKey: 'txn_settlement_percent',
      value: { Percent: txn.local_settlement_percent },
      color: getSettlementPercentColor(txn),
    },
    {
      headingKey: 'match_status',
      value: { String: getMatchStatusLabel(txn.local_match_status) },
      color: getMatchStatusColor(txn.local_match_status),
      alert: getReconAlert(txn.local_match_status),
    },
  ]
}

function unreconciledBankTxnCells(
  txn: ReconUnreconciledBankTxn,
  bankBalance: number,
  arcfinaAmount: number,
  arcfinaBalance: number
): ReconGridCell[] {
  return [
    {
      headingKey: 'action',
      value: 'None',
    },
    {
      headingKey: 'record_date',
      value: 'None',
    },
    {
      headingKey: 'record_description',
      value: 'None',
    },
    {
      headingKey: 'record_amount',
      value: 'None',
    },
    {
      headingKey: 'record_amount_reconciled',
      value: 'None',
    },
    {
      headingKey: 'record_amount_to_reconcile',
      value: 'None',
    },
    {
      headingKey: 'record_reconciliation_status',
      value: 'None',
      leftDivider: true,
    },
    {
      headingKey: 'match_status',
      value: 'None',
      leftDivider: true,
    },
    {
      headingKey: 'arc_amount',
      value: { Float: arcfinaAmount },
      leftDivider: true,
    },
    {
      headingKey: 'bank_balance',
      value: 'None',
    },
    {
      headingKey: 'arc_balance',
      value: { Float: arcfinaBalance },
    },
    // ledger part
    {
      headingKey: 'txn_settlement_date',
      value: { NaiveDate: txn.bank_datetime.substring(0, 10) },
    },
    {
      headingKey: 'txn_datetime',
      value: { DateTime: txn.txn_datetime },
    },
    {
      headingKey: 'txn_segment_ref',
      value: { String: txn.segment_ref },
    },
    {
      headingKey: 'txn_portfolio',
      value: { String: txn.portfolio_name },
    },
    {
      headingKey: 'txn_description',
      value: { String: txn.description },
    },
    {
      headingKey: 'txn_asset_type',
      value: { String: txn.asset_type_name || '-' },
    },
    {
      headingKey: 'txn_counterparty',
      value: { String: txn.cparty_name || '-' },
    },
    {
      headingKey: 'txn_quantity',
      value: { Float: txn.txn_amount },
    },
    {
      headingKey: 'txn_quantity_to_settle',
      value: { String: '-' },
    },
    {
      headingKey: 'txn_settlement_percent',
      value: { String: '-' },
    },
  ]
}

function getReconStatusLabel(_status: MatchStatus, isReconciled: boolean): string {
  if (isReconciled) {
    return t('bankAccounts:recon_status.reconciled')
  }
  return t('bankAccounts:recon_status.not_reconciled')
}

function getMatchStatusLabel(status: MatchStatus): string {
  switch (status) {
    case 'no_match':
      return t('bankAccounts:recon_status.no_match')
    case 'partial_match':
      return t('bankAccounts:recon_status.partial_match')
    case 'full_match':
      return t('bankAccounts:recon_status.full_match')
    case 'reconciled':
      return t('bankAccounts:recon_status.reconciled')
    case 'reconciled_err':
      return t('bankAccounts:recon_status.reconciled')
  }
}

function getReconStatusColor(_status: MatchStatus, isReconciled: boolean): string {
  if (isReconciled) {
    return 'success.main'
  }
  return 'error.main'
}

const yellow = '#FFCA7B'
const blue = '#29B6F6' // same as info button bg

function getMatchStatusColor(status: MatchStatus): string {
  switch (status) {
    case 'no_match':
      return 'error.main'
    case 'partial_match':
      return yellow
    case 'full_match':
      return blue
    case 'reconciled':
      return 'success.main'
    case 'reconciled_err':
      return 'success.main'
  }
}

function getSettlementPercentColor(txn: ReconTxnLocal | null): string {
  // prevent division by zero error
  if (!txn || txn.quantity === 0) {
    return ''
  }

  if (txn.local_has_matches) {
    return blue
  }

  const settlePercent = txn.quantity_settled / txn.quantity
  if (settlePercent > 1) {
    return 'error.main'
  } else if (settlePercent === 1) {
    return 'success.main'
  } else if (settlePercent !== 0) {
    return yellow
  }

  // when nothing is settled nor matched
  return 'error.main'
}

function getReconAlert(status: MatchStatus): GridAlert | undefined {
  if (status === 'reconciled_err') {
    return {
      level: 'breach',
      message: t('bankAccounts:recon_status:reconciled_err'),
    }
  }
}

//
// Headings definitions
//

export const defaultInitialWidth = 120
const actionWidth = 50
const dateWidth = 140
const amountWidth = 150
const descriptionWidth = 360
const statusWidth = 140
const txnRefWidth = 140

function bankHeadings(filterOptions: FilterOptions, isReconciling: boolean): ReconGridHeading[] {
  return [
    {
      key: 'action',
      title: '',
      pinned: true,
      hide: !isReconciling,
      width: actionWidth,
    },
    {
      key: 'record_date',
      title: t('bankAccounts:recon_table.record_date'),
      initialWidth: defaultInitialWidth,
      filterType: { type: 'date' },
    },
    {
      key: 'record_description',
      title: t('bankAccounts:recon_table.record_description'),
      initialWidth: descriptionWidth,
      filterType: { type: 'string' },
    },
    {
      key: 'record_amount',
      title: t('bankAccounts:recon_table.record_amount'),
      initialWidth: amountWidth,
      filterType: { type: 'number' },
    },
    {
      key: 'bank_balance',
      title: t('bankAccounts:recon_table.bank_balance'),
      initialWidth: amountWidth,
      filterType: { type: 'number' },
    },
    // used when debugging is needed
    // {
    //   key: 'record_amount_reconciled',
    //   title: t('bankAccounts:recon_table.record_amount_reconciled'),
    //   initialWidth: amountWidth,
    // },
    // {
    //   key: 'record_amount_to_reconcile',
    //   title: t('bankAccounts:recon_table.record_amount_to_reconcile'),
    //   initialWidth: amountWidth,
    // },
    {
      key: 'record_reconciliation_status',
      title: t('bankAccounts:recon_table.record_reconciliation_status'),
      hide: isReconciling,
      initialWidth: statusWidth,
      filterType: { type: 'select', options: filterOptions.reconciliationStatus },
    },
    {
      key: 'match_status',
      title: t('bankAccounts:recon_table.match_status'),
      hide: !isReconciling,
      initialWidth: statusWidth,
      filterType: { type: 'select', options: filterOptions.matchStatus },
    },
    {
      key: 'arc_amount',
      title: t('bankAccounts:recon_table.arc_amount'),
      initialWidth: amountWidth,
      filterType: { type: 'number' },
    },
    {
      key: 'arc_balance',
      title: t('bankAccounts:recon_table.arc_balance'),
      initialWidth: amountWidth,
      filterType: { type: 'number' },
    },
    {
      key: 'txn_settlement_date',
      title: t('bankAccounts:recon_table.txn_settlement_date'),
      initialWidth: defaultInitialWidth,
      filterType: { type: 'date' },
    },
    {
      key: 'txn_datetime',
      title: t('bankAccounts:recon_table.txn_datetime'),
      hide: isReconciling,
      initialWidth: dateWidth,
      filterType: { type: 'date' },
    },
    {
      key: 'txn_segment_ref',
      title: t('bankAccounts:recon_table.txn_segment_ref'),
      initialWidth: txnRefWidth,
      filterType: { type: 'string' },
    },
    {
      key: 'txn_description',
      title: t('bankAccounts:recon_table.txn_description'),
      hide: isReconciling,
      initialWidth: descriptionWidth,
      filterType: { type: 'string' },
    },
    {
      key: 'txn_asset_type',
      title: t('bankAccounts:recon_table.txn_asset_type'),
      hide: isReconciling,
      initialWidth: defaultInitialWidth,
      filterType: { type: 'select', options: filterOptions.assetTypes },
    },
    {
      key: 'txn_portfolio',
      title: t('bankAccounts:recon_table.txn_portfolio'),
      hide: isReconciling,
      initialWidth: defaultInitialWidth,
      filterType: { type: 'select', options: filterOptions.portfolios },
    },
    {
      key: 'txn_counterparty',
      title: t('bankAccounts:recon_table.txn_counterparty'),
      hide: isReconciling,
      initialWidth: defaultInitialWidth,
      filterType: { type: 'select', options: filterOptions.counterparties },
    },
    {
      key: 'txn_quantity',
      title: t('bankAccounts:recon_table.txn_quantity'),
      hide: isReconciling,
      initialWidth: amountWidth,
      filterType: { type: 'number' },
    },
    {
      key: 'txn_quantity_to_settle',
      title: t('bankAccounts:recon_table.txn_quantity_to_settle'),
      hide: isReconciling,
      initialWidth: amountWidth,
      filterType: { type: 'number' },
    },
    {
      key: 'txn_settlement_percent',
      title: t('bankAccounts:recon_table.txn_settlement_percent'),
      hide: isReconciling,
      initialWidth: defaultInitialWidth,
      filterType: { type: 'percent' },
    },
  ]
}

function ledgerHeadings(filterOptions: FilterOptions, isReconciling: boolean): ReconGridHeading[] {
  return [
    {
      key: 'action',
      title: '',
      pinned: true,
      hide: !isReconciling,
      width: actionWidth,
    },
    {
      key: 'txn_settlement_date',
      title: t('bankAccounts:recon_table.txn_settlement_date'),
      initialWidth: defaultInitialWidth,
      filterType: { type: 'date' },
    },
    {
      key: 'txn_segment_ref',
      title: t('bankAccounts:recon_table.txn_segment_ref'),
      initialWidth: txnRefWidth,
      filterType: { type: 'string' },
    },
    {
      key: 'txn_description',
      title: t('bankAccounts:recon_table.txn_description'),
      initialWidth: descriptionWidth,
      filterType: { type: 'string' },
    },
    {
      key: 'txn_asset_type',
      title: t('bankAccounts:recon_table.txn_asset_type'),
      initialWidth: defaultInitialWidth,
      filterType: { type: 'select', options: filterOptions.assetTypes },
    },
    {
      key: 'txn_datetime',
      title: t('bankAccounts:recon_table.txn_datetime'),
      initialWidth: dateWidth,
      filterType: { type: 'date' },
    },
    {
      key: 'txn_portfolio',
      title: t('bankAccounts:recon_table.txn_portfolio'),
      initialWidth: defaultInitialWidth,
      filterType: { type: 'select', options: filterOptions.portfolios },
    },
    {
      key: 'txn_counterparty',
      title: t('bankAccounts:recon_table.txn_counterparty'),
      initialWidth: defaultInitialWidth,
      filterType: { type: 'select', options: filterOptions.counterparties },
    },
    {
      key: 'txn_quantity',
      title: t('bankAccounts:recon_table.txn_quantity'),
      initialWidth: amountWidth,
      filterType: { type: 'number' },
    },
    {
      key: 'txn_quantity_to_settle',
      title: t('bankAccounts:recon_table.txn_quantity_to_settle'),
      initialWidth: amountWidth,
      filterType: { type: 'number' },
    },
    {
      key: 'txn_settlement_percent',
      title: t('bankAccounts:recon_table.txn_settlement_percent'),
      initialWidth: defaultInitialWidth,
      filterType: { type: 'percent' },
    },
    // used when debugging is needed
    // {
    //   key: 'match_status',
    //   title: t('bankAccounts:recon_table.match_status'),
    //   hide: !isReconciling,
    //   initialWidth: statusWidth,
    // },
  ]
}

type FilterOptions = {
  assetTypes: ReconFilterOption[]
  portfolios: ReconFilterOption[]
  counterparties: ReconFilterOption[]
  reconciliationStatus: ReconFilterOption[]
  matchStatus: ReconFilterOption[]
}

const reconciliationStatusFilterOptions: (ReconFilterOption & { value: MatchStatus })[] = [
  { value: 'no_match', title: t('bankAccounts:recon_status.not_reconciled') },
  { value: 'reconciled', title: t('bankAccounts:recon_status.reconciled') },
  { value: 'reconciled_err', title: t('bankAccounts:recon_status.reconciled_err') },
]

const matchStatusFilterOptions: (ReconFilterOption & { value: MatchStatus })[] = [
  { value: 'no_match', title: t('bankAccounts:recon_status.no_match') },
  { value: 'partial_match', title: t('bankAccounts:recon_status.partial_match') },
  { value: 'full_match', title: t('bankAccounts:recon_status.full_match') },
  { value: 'reconciled', title: t('bankAccounts:recon_status.reconciled') },
  { value: 'reconciled_err', title: t('bankAccounts:recon_status.reconciled_err') },
]

function collectFilterOptions(grid: ReconGridResponse): FilterOptions {
  const assetTypes = new Map<string, ReconFilterOption>()
  const portfolios = new Map<string, ReconFilterOption>()
  const counterparties = new Map<number, ReconFilterOption>()

  for (const txn of grid.txns) {
    if (txn.asset_type && !assetTypes.has(txn.asset_type)) {
      assetTypes.set(txn.asset_type, { value: txn.asset_type, title: txn.asset_type_name || '' })
    }
    if (!portfolios.has(txn.portfolio_ref)) {
      portfolios.set(txn.portfolio_ref, { value: txn.portfolio_ref, title: txn.portfolio_name })
    }
    if (txn.cparty_id && !counterparties.has(txn.cparty_id)) {
      counterparties.set(txn.cparty_id, { value: String(txn.cparty_id), title: txn.cparty_name || '' })
    }
  }

  for (const txn of grid.unreconciled_bank_txns) {
    if (txn.asset_type && !assetTypes.has(txn.asset_type)) {
      assetTypes.set(txn.asset_type, { value: txn.asset_type, title: txn.asset_type_name || '' })
    }
    if (!portfolios.has(txn.portfolio_ref)) {
      portfolios.set(txn.portfolio_ref, { value: txn.portfolio_ref, title: txn.portfolio_name })
    }
    if (txn.cparty_id && !counterparties.has(txn.cparty_id)) {
      counterparties.set(txn.cparty_id, { value: String(txn.cparty_id), title: txn.cparty_name || '' })
    }
  }

  return {
    assetTypes: assetTypes.values().toArray(),
    portfolios: portfolios.values().toArray(),
    counterparties: counterparties.values().toArray(),
    reconciliationStatus: reconciliationStatusFilterOptions,
    matchStatus: matchStatusFilterOptions,
  }
}
