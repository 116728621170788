import { Dialog, Alert as MuiAlert, Typography } from '@mui/material'
import { FormEvent, ReactNode, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import NumericField from '../../../components/fields/numeric-field'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import { ModalDetailRow } from '../../../components/modal-detail-row'
import ModalTitle from '../../../components/modal-title'
import { ReconNetZeroMatchLocal } from '../../../services/data/types/reconciliation'
import { formatDatetime } from '../../../utils/dates'
import { newId } from '../../../utils/id'
import { defaultInputDecimalPlaces, formatFloat } from '../../../utils/numbers'
import { SelectedTxns } from '../data/reconciliation-data'
import { FormRow } from './match-modal'

type MatchNetZeroModalProps = {
  open: boolean
  bankAccountId: number
  selectedTxns: SelectedTxns
  onSave: (match: ReconNetZeroMatchLocal) => void
  onClose: () => void
}

export function MatchNetZeroModal(props: MatchNetZeroModalProps) {
  const { open, bankAccountId, selectedTxns, onSave, onClose } = props

  const { t } = useTranslation('bankAccounts')

  const formRef = useRef<HTMLFormElement>(null)

  const total = selectedTxns.reduce((acc, t) => acc + t.local_quantity_to_settle, 0)
  let canMatch = total === 0

  function handleClose() {
    onClose()
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (!canMatch) {
      return
    }

    const settlementDates = selectedTxns.map((t) => t.settlement_date)
    settlementDates.sort()
    const latestSettlement = settlementDates[settlementDates.length - 1]

    if (!latestSettlement) {
      return
    }

    const reconMatch: ReconNetZeroMatchLocal = {
      record_id: newId() * -1,
      account_id: bankAccountId,
      record_date: latestSettlement,
      txns: [],
    }

    for (const txn of selectedTxns) {
      // when root txn is a bank txn a single bank txn is expected and they are the same
      if (!txn.is_bank_txn) {
        for (const bankTxn of txn.bank_txns) {
          if (bankTxn.is_reconciled) {
            return
          }
          reconMatch.txns.push({
            ext_segment_ref: bankTxn.ext_segment_ref,
            amount: bankTxn.amount,
            portfolio_ref: txn.portfolio_ref,
            bank_txn_ref: bankTxn.txn_ref,
            cparty_id: txn.cparty_id,
            note: bankTxn.description,
          })
        }
      }

      reconMatch.txns.push({
        ext_segment_ref: txn.ext_segment_ref,
        amount: txn.local_quantity_to_settle,
        portfolio_ref: txn.portfolio_ref,
        bank_txn_ref: txn.is_bank_txn ? txn.bank_txns[0]?.txn_ref || null : null,
        cparty_id: txn.cparty_id,
        note: txn.description,
      })
    }

    onSave(reconMatch)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
      PaperProps={{
        sx: {
          position: 'fixed',
          top: '10%',
          maxHeight: '80%',
          my: 0,
        },
      }}
    >
      <form ref={formRef} onSubmit={handleSubmit}>
        <ModalTitle title={t('match_modal.net_zero_title')} onClose={handleClose} />
        <ModalContent>
          <MuiAlert
            severity="info"
            variant="filled"
            sx={{
              color: 'gray.700',
              background: 'rgba(255, 255, 255, 0.08)',
            }}
          >
            {t('match_modal.net_zero_alert')}
          </MuiAlert>

          <Typography variant="subtitle1">{t('match_modal.transactions')}</Typography>

          {selectedTxns.map((txn) => {
            const rows: ReactNode[] = []

            // when root txn is a bank txn a single bank txn is expected and they are the same
            if (!txn.is_bank_txn) {
              for (const bankTxn of txn.bank_txns) {
                if (bankTxn.is_reconciled) {
                  continue
                }

                const label = `${formatDatetime(bankTxn.txn_datetime)}  |  ${formatFloat(bankTxn.amount)}`

                rows.push(
                  <FormRow
                    key={bankTxn.ext_segment_ref}
                    label={
                      <div>
                        <div style={{ color: 'white' }}>{label}</div>
                        <div>{bankTxn.description}</div>
                      </div>
                    }
                  >
                    <NumericField
                      disabled
                      fixedLabel
                      size="small"
                      label={t('common:amount')}
                      value={String(bankTxn.amount)}
                      decimalPlaces={defaultInputDecimalPlaces}
                    />
                  </FormRow>
                )
              }
            }

            const label = `${txn.settlement_date}  |  ${formatFloat(txn.quantity)}`

            rows.push(
              <FormRow
                key={txn.ext_segment_ref}
                label={
                  <div>
                    <div style={{ color: 'white' }}>{label}</div>
                    <div>{txn.description}</div>
                  </div>
                }
              >
                <NumericField
                  disabled
                  fixedLabel
                  size="small"
                  label={t('common:amount')}
                  value={String(txn.local_quantity_to_settle)}
                  decimalPlaces={defaultInputDecimalPlaces}
                />
              </FormRow>
            )

            return rows
          })}

          <ModalDetailRow label={t('match_modal.total')}>
            <Typography variant="body2" fontSize="small" color={canMatch ? 'success.main' : 'error'}>
              <span title={String(total)}>{formatFloat(total)}</span>
              <span>{' / '}</span>
              <span>{formatFloat(0)}</span>
            </Typography>
          </ModalDetailRow>

          {!canMatch && <Alert severity="error" message={t('match_modal.net_zero_sum_error')} />}
        </ModalContent>

        <ModalActions
          confirmLabel={t('match_modal.confirm_match')}
          confirmDisabled={!canMatch}
          onCancel={handleClose}
        />
      </form>
    </Dialog>
  )
}
