import { formatFloat } from '../../../utils/numbers'

// Matches https://github.com/arcfina/arc_backend/blob/main/crates/uam/uam_sdk/src/uam_master/txn_terms/interface.rs
export type PriceUpPayload = {
  asset_type: string

  // UamPriceBy fields:
  quantity_type: QuantityType | undefined
  quantity: number | undefined
  price: number | undefined
  spread_bps: number | undefined
  consideration: number | undefined

  static_data: {
    [datapointAlias in 'contract_size' | 'par_value' | 'coupon_freq']?: {
      [key in 'Float']: number
    }
  } & {
    [datapointAlias in 'coupon' | 'recovery_rate']?: {
      [key in 'Percent']: number
    }
  } & {
    [datapointAlias in 'asset_currency']?: {
      [key in 'AssetRef']: string
    }
  } & {
    [datapointAlias in 'maturity_date' | 'issue_date']?: {
      [key in 'NaiveDate']: string
    }
  } & {
    [datapointAlias in 'day_count']?: {
      [key in 'String']: string
    }
  }
  proposed_terms: {
    [contractTermTag in
      | 'accrued_interest'
      | 'contract_spread'
      | 'contract_price'
      | 'haircut'
      | 'factor'
      | 'inception_fx'
      | 'initial_margin_rate']?: {
      [key in 'Float']: number
    }
  } & {
    [contractTermTag in 'settlement_date' | 'fixing_date' | 'swap_effective_date']?: {
      [key in 'NaiveDate']: string
    }
  } & {
    [contractTermTag in 'is_give_up' | 'is_fully_funded' | 'settle_on_quote' | 'margin_in_quote_currency']?: {
      [key in 'Boolean']: boolean
    }
  } & {
    [contractTermTag in 'tranche']?: {
      [key in 'String']: string
    }
  }
}

export type StaticDataFields = {
  assetCurrencyAssetRef: string
  assetCurrencySymbol: string
  baseCurrencySymbol: string
  quoteCurrencySymbol: string
  contractSize: number | null
  parValue: number | null
  maturityDate: string | null
  issueDate: string | null
  coupon: number | null
  couponFreq: number | null
  recoveryRate: number | null
  dayCount: string | null
}

// Matches https://github.com/arcfina/arc_backend/blob/main/crates/uam/uam_sdk/src/common/types/trade_particulars.rs
export type TradeParticulars = {
  quantity_type: QuantityType
  quantity: number
  notional?: number | null
  number_of?: number | null
  consideration?: number | null
  market_value?: number | null
  price?: number | null
  clean_price?: number | null
  dirty_price?: number | null
  contract_price?: number | null
  contract_size?: number | null
  contract_spread?: number | null
  spread_bps?: number | null
  par_value?: number | null
  accrued_interest?: number | null
  accrued_explainer?: string | null
  sink_factor?: number | null
  haircut?: number | null
  funding_amount_lc?: number | null
  inception_fx?: number | null
  funding_amount?: number | null
  spot_rate?: number | null
  forward_rate?: number | null
  exit_consideration?: number | null
  repo_funding_amount?: number | null
  trs_funding_amount?: number | null
  quote_amount?: number | null
  settlement_date?: string | null
  settlement_amount?: number | null
  settlement_price?: number | null
  actual_settlement_date?: string | null
  fixing_date?: string | null
  fixing_price?: number | null
  fixing_rate?: number | null
  is_give_up?: boolean | null
  ndf_rate?: number | null
  initial_margin_rate?: number | null
  settle_on_quote?: boolean | null
  margin_in_quote_currency?: boolean | null
  is_fully_funded?: boolean | null
  cashflow_type?: string | null
  related_asset?: string | null
  is_cds_index?: boolean
  upfront_charge?: number
  upfront_explainer?: string | null
  swap_effective_date?: string | null
  tranche?: string | null
}

export type QuantityType = 'number_of' | 'notional'

/**
 * Parse Trade Particular value into a string.
 *
 * Used to represent input values on the Trade Ticket screens.
 */
export function parseTPValue(value: number | null | undefined, noAbs?: boolean) {
  if (typeof value !== 'number') {
    return ''
  }

  if (noAbs) {
    return String(value)
  } else {
    return String(Math.abs(value))
  }
}

/**
 * Parse Trade Particular percentage value into a string.
 *
 * Used to represent input values on the Trade Ticket screens.
 */
export function parseTPPercentageValue(value: number | null | undefined) {
  if (typeof value !== 'number') {
    return ''
  }
  return String(Math.abs(value) * 100)
}

/**
 * Format Trade Particular value.
 */
export function formatTPValue(value: number | null | undefined, suffix?: string) {
  if (typeof value !== 'number') {
    return '-'
  }

  const str = formatFloat(Math.abs(value))

  if (suffix) {
    return `${str} ${suffix}`
  }
  return str
}

/**
 * Format Trade Particular percentage value.
 */
export function formatTPPercentageValue(value: number | null | undefined) {
  if (typeof value !== 'number') {
    return '-'
  }

  const str = formatFloat(Math.abs(value) * 100)

  return `${str} %`
}
