import { Add, Search } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogActions,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { Box, Container, Stack } from '@mui/system'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ChangeEvent, FormEvent, SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import Alert from '../../../components/alert'
import AppLayout from '../../../components/layouts/app-layout'
import { queryClient } from '../../../services/data/react-query'
import { isDatapointValue, isEditDatapointValue } from '../../../services/data/types/datapoint'
import { TradeParticulars, parseTPPercentageValue, parseTPValue } from '../../../services/data/types/pricing'
import {
  ConfirmDealsPayload,
  ConsignNewDeal,
  ConsignNewTicketPayload,
  ConsignmentDealSegment,
  CorrectTransactionPayload,
  EditLegAsset,
  Leg,
  LegField,
  TradeDetails,
  TradeParticularsFields,
  TradeProfile,
  TradeSecurity,
  TradeTicketResponse,
  isTradeProfileSupported,
} from '../../../services/data/types/trade-ticket'
import { datetimeFormat, datetimePlaceholder, formatNaiveDate, parseNaiveDate } from '../../../utils/dates'
import useOpenState from '../../../utils/hooks/use-open-state'
import { newId } from '../../../utils/id'
import EditAssetStaticModal from '../../data-engine/components/edit-asset-static-modal'
import { EditAssetParams } from '../../data-engine/data/use-edit-asset-datapoint-mutation'
import usePortfoliosQuery from '../../portfolios/data/use-portfolios-query'
import useUsersQuery from '../../settings/data/use-users-query'
import useCloseOutPositionQuery from '../data/use-close-out-position-query'
import {
  CloseOutParams,
  useCloseOutTransactionCommitMutation,
  useCloseOutTransactionVerifyMutation,
} from '../data/use-close-out-transaction-mutations'
import useCloseOutTransactionQuery from '../data/use-close-out-transaction-query'
import { useConfirmDealsMutation } from '../data/use-confirm-deals-mutation'
import { useConsignNewTicketMutation } from '../data/use-consign-new-ticket-mutation'
import { useCorrectTransactionMutation } from '../data/use-correct-transaction-mutation'
import useDealQuery from '../data/use-deal-query'
import useTradeTicketQuery from '../data/use-trade-ticket-query'
import useTransactionQuery from '../data/use-transaction-query'
import { checkAliasError } from './check-alias-error'
import { SecurityLeg } from './security-leg'
import SelectCurrencyModal from './select-currency-modal'
import SelectItemModal from './select-item-modal'
import SelectSecurityModal from './select-security-modal'

type TradeTicketRootProps = {
  onCloseView?: () => void
}

function TradeTicketRoot(props: TradeTicketRootProps) {
  const { onCloseView } = props
  const { t } = useTranslation('tradeTicket')

  const params = useParams()
  const [searchParams] = useSearchParams()

  const [legs, setLegs] = useState<Leg[]>([])
  const [editLeg, setEditLeg] = useState<Leg | null>(null)
  const [editAsset, setEditAsset] = useState<EditLegAsset>()

  const [strategy, setStrategy] = useState('')
  const [brokerAccountId, setBrokerAccountId] = useState('')
  const [notes, setNotes] = useState('')
  const [executedAt, setExecutedAt] = useState<Date | null>(null)
  const [originalTxnRef, setOriginalTxnRef] = useState('')

  const securityModal = useOpenState()
  const cashflowModal = useOpenState()

  const [errorMessage, setErrorMessage] = useState('')

  const yRef = params.yRef
  const dealRef = params.dealRef
  const transactionRef = params.transactionRef
  const portfolioRef = params.portfolioRef
  const closeTransactionRef = params.closeTransactionRef

  const datasetRef = searchParams.get('datasetRef')
  const aggregations = searchParams.get('aggregations')

  const usersQuery = useUsersQuery()
  const users = usersQuery.data?.data || []

  const portfoliosQuery = usePortfoliosQuery()
  const portfolios = portfoliosQuery.data?.data || []
  const portfolio = portfolios.find((p) => p.portfolio_ref === portfolioRef)
  const [selectedPortfolioRef, setSelectedPortfolioRef] = useState(portfolioRef)

  const transactionQuery = useTransactionQuery(portfolio?.lei, transactionRef)
  const transaction = transactionQuery.data?.data

  const dealQuery = useDealQuery(portfolio?.lei, dealRef)
  const deal = dealQuery.data?.data

  const closeOutPositionQuery = useCloseOutPositionQuery(selectedPortfolioRef, yRef, aggregations, datasetRef)
  const closeOutPosition = closeOutPositionQuery.data?.data

  const closeOutTransactionQuery = useCloseOutTransactionQuery(portfolio?.lei, closeTransactionRef)
  const closeOutTransaction = closeOutTransactionQuery.data?.data

  const closeOutData = closeOutPosition || closeOutTransaction

  const isCashflow = searchParams.has('cashflow') || legs.some((leg) => leg.tradeProfile === 'cashflow')

  const isTransactionCorrection = !!transactionRef
  const isDealConfirmation = !!dealRef
  const isCloseOutPosition = !!yRef
  const isCloseOutTransaction = !!closeTransactionRef
  const isNewTicket = !isTransactionCorrection && !isDealConfirmation && !isCloseOutPosition && !isCloseOutTransaction

  const isTransactionCorrectionLoaded = isTransactionCorrection && transactionQuery.isFetched
  const isDealConfirmationLoaded = isDealConfirmation && dealQuery.isFetched
  const isCloseOutPositionLoaded = isCloseOutPosition && closeOutPositionQuery.isFetched
  const isCloseOutTransactionLoaded = isCloseOutTransaction && closeOutTransactionQuery.isFetched && legs.length === 1
  const showLoadingScreen =
    !isTransactionCorrectionLoaded &&
    !isDealConfirmationLoaded &&
    !isCloseOutPositionLoaded &&
    !isCloseOutTransactionLoaded &&
    !isNewTicket

  const canEditLeg = !isDealConfirmation && !isCloseOutTransactionLoaded
  const canDeleteLeg = !isTransactionCorrection && !isDealConfirmation && !isCloseOutTransactionLoaded
  const canAddLeg = !isTransactionCorrection && !isDealConfirmation && !isCloseOutTransactionLoaded

  const canChangePortfolio = isNewTicket || isTransactionCorrection || isDealConfirmation
  const showOriginalTxn = isNewTicket || isTransactionCorrection || isDealConfirmation || isCloseOutPosition

  const pageTitle = isTransactionCorrection
    ? t('transaction_correction')
    : isCloseOutTransaction
      ? t('close_out_transaction')
      : isDealConfirmation
        ? t('trade_confirmation')
        : isCashflow
          ? t('cashflow')
          : t('trade_ticket')
  const executedAtLabel = isCloseOutTransaction ? t('closed_at') : t('executed_at')
  const submitLabel = isTransactionCorrection
    ? t('correct_transaction')
    : isCloseOutTransaction
      ? t('close_out_transaction')
      : isDealConfirmation
        ? t('confirm_trade')
        : t('record_on_ledger')

  const tradeTicketQuery = useTradeTicketQuery({ enabled: true })
  const tradeTicket = tradeTicketQuery.data?.data || null
  const strategies = tradeTicket?.strategy_tags || []
  const brokers = tradeTicket?.brokers || []
  const assetTypes = tradeTicket?.asset_types || []
  const currencies = tradeTicket?.currencies || {}
  const tradeProfiles = tradeTicket?.trade_profiles || []
  const indexes = tradeTicket?.indexes || {}
  const refRates = tradeTicket?.ref_rates || {}
  const regularCurves = tradeTicket?.regular_curves || {}
  const cashflowTypes = tradeTicket?.cashflow_types || []

  const editAssetSecurity = legs
    .find((leg) => leg.id === editAsset?.legId)
    ?.staticFields.find((sf) => sf.datapointRef === editAsset?.datapointRef)?.security

  const executedAtDateLimit = new Date(new Date().setHours(23, 59, 59, 999))

  const consignNewTicket = useConsignNewTicketMutation()
  const correctTransaction = useCorrectTransactionMutation()
  const confirmDeals = useConfirmDealsMutation()
  const closeOutTransactionVerify = useCloseOutTransactionVerifyMutation()
  const closeOutTransactionCommit = useCloseOutTransactionCommitMutation()

  const error =
    errorMessage ||
    consignNewTicket.error ||
    correctTransaction.error ||
    confirmDeals.error ||
    dealQuery.error ||
    transactionQuery.error ||
    closeOutPositionQuery.error ||
    closeOutTransactionQuery.error ||
    closeOutTransactionVerify.error ||
    closeOutTransactionCommit.error
  const legsHaveAliasError = legs.some((leg) => !!leg.aliasError)
  const legsHaveUnsupportedTradeProfile = legs.some((leg) => !isTradeProfileSupported(leg.tradeProfile))
  const isSubmitting =
    consignNewTicket.isLoading ||
    correctTransaction.isLoading ||
    confirmDeals.isLoading ||
    closeOutTransactionVerify.isLoading ||
    closeOutTransactionCommit.isLoading
  const submitDisabled =
    !selectedPortfolioRef ||
    consignNewTicket.isLoading ||
    correctTransaction.isLoading ||
    confirmDeals.isLoading ||
    !legs.length ||
    legsHaveAliasError ||
    legsHaveUnsupportedTradeProfile

  useEffect(() => {
    setExecutedAt(new Date())
    if (isNewTicket && !legs.length) {
      handleAddLeg()
    }
  }, [])

  useEffect(() => {
    if (transaction && tradeTicketQuery.isFetched) {
      const legs = transaction.segments.map((segment) => {
        const tradeAssetType = assetTypes.find((assetType) => assetType.asset_type_tag === segment.asset_type)
        const profiles = tradeProfiles.filter((tradeProfile) =>
          tradeAssetType?.trade_profiles.includes(tradeProfile.tag)
        )

        const aliasError = checkAliasError(tradeAssetType?.static_fields || [], segment.trade_profile)

        const leg: Leg = {
          id: newId(),

          transactionRef: transaction.txn_ref,
          dealTransactions: undefined,

          security: {
            identifier: '',
            identifierType: '',
            assetRef: segment.asset_ref,
            assetName: segment.asset_name,
            assetType: segment.asset_type,
            assetTypeName: segment.asset_type_name,
          },

          staticFields: [],
          tradeAssetType,
          profiles,

          aliasError,
          pricingError: '',

          tradeProfile: segment.trade_profile,
          tradeDirection: segment.trade_direction,
          commonParticulars: segment.common_particulars,

          ...mapTradeParticularsToLeg(
            segment.trade_profile,
            segment.trade_particulars,
            segment.consideration_asset_ref
          ),
        }

        return leg
      })

      setLegs(legs)
      setStrategy(transaction.strategy || '')
      setBrokerAccountId(String(transaction.broker_account_id))
      setNotes(transaction.note || '')
      setExecutedAt(new Date(transaction.txn_datetime))
      setOriginalTxnRef(transaction.segments[0]?.original_segment_ref || '')
    }
  }, [transaction, tradeTicketQuery.isFetched])

  useEffect(() => {
    if (deal && tradeTicketQuery.isFetched) {
      const legs = deal.deal_segments.map((segment) => {
        const tradeAssetType = assetTypes.find((assetType) => assetType.asset_type_tag === segment.asset_type)
        const profiles = tradeProfiles.filter((tradeProfile) =>
          tradeAssetType?.trade_profiles.includes(tradeProfile.tag)
        )

        const aliasError = checkAliasError(tradeAssetType?.static_fields || [], segment.trade_profile)

        const leg: Leg = {
          id: newId(),

          transactionRef: undefined,
          dealTransactions: segment.deal_transactions,

          security: {
            identifier: '',
            identifierType: '',
            assetRef: segment.asset_ref,
            assetName: segment.asset_name,
            assetType: segment.asset_type,
            assetTypeName: segment.asset_type_name,
          },

          staticFields: [],
          tradeAssetType,
          profiles,

          aliasError,
          pricingError: '',

          tradeProfile: segment.trade_profile,
          tradeDirection: segment.trade_direction,
          commonParticulars: segment.common_particulars,

          ...mapTradeParticularsToLeg(
            segment.trade_profile,
            segment.trade_particulars,
            segment.consideration_asset_ref
          ),
        }

        return leg
      })

      setLegs(legs)
      setStrategy(deal.strategy || '')
      setBrokerAccountId(String(deal.broker_account_id))
      setNotes(deal.note || '')
      setExecutedAt(new Date(deal.deal_datetime))
      setOriginalTxnRef(deal.deal_segments[0]?.original_segment_ref || '')
    }
  }, [deal, tradeTicketQuery.isFetched])

  useEffect(() => {
    if (closeOutData && tradeTicketQuery.isFetched) {
      const legs = closeOutData.legs.map((closeLeg) => {
        const tradeAssetType = assetTypes.find((assetType) => assetType.asset_type_tag === closeLeg.asset_type)
        const profiles = tradeProfiles.filter((tradeProfile) =>
          tradeAssetType?.trade_profiles.includes(tradeProfile.tag)
        )

        const aliasError = checkAliasError(tradeAssetType?.static_fields || [], closeLeg.trade_profile)

        const leg: Leg = {
          id: newId(),

          transactionRef: undefined,
          dealTransactions: undefined,

          security: {
            identifier: '',
            identifierType: '',
            assetRef: closeLeg.asset_ref,
            assetName: closeLeg.asset_name,
            assetType: closeLeg.asset_type,
            assetTypeName: closeLeg.asset_type_name,
          },

          staticFields: [],
          tradeAssetType,
          profiles,

          aliasError,
          pricingError: '',

          tradeProfile: closeLeg.trade_profile,
          tradeDirection: closeLeg.trade_direction,
          commonParticulars: closeLeg.common_particulars,

          ...mapTradeParticularsToLeg(
            closeLeg.trade_profile,
            closeLeg.trade_particulars,
            closeLeg.consideration_asset_ref
          ),
        }

        return leg
      })

      setLegs(legs)
      setBrokerAccountId(String(closeOutData.broker_account_id || ''))
      setStrategy(closeOutData.strategy || '')
    }
  }, [closeOutData, tradeTicketQuery.isFetched])

  function handleSaveSecurityLeg(security: Leg['security']) {
    clearErrorMessages()

    const tradeAssetType = assetTypes.find((assetType) => assetType.asset_type_tag === security.assetType)
    const profiles = tradeProfiles.filter((tradeProfile) => tradeAssetType?.trade_profiles.includes(tradeProfile.tag))

    const tradeProfile = tradeAssetType?.trade_profiles[0] || 'standard'

    const aliasError = checkAliasError(tradeAssetType?.static_fields || [], tradeProfile)

    let staticFields: LegField[] = editLeg?.staticFields || []
    const isExistingAsset = !!security.assetRef
    if (isExistingAsset) {
      const legWithSameAsset = legs.find((leg) => leg.security.assetRef === security.assetRef)
      if (legWithSameAsset) {
        staticFields = [...legWithSameAsset.staticFields]
      }
    }

    if (editLeg) {
      const newLegs = legs.map((leg) => {
        if (leg.id === editLeg.id) {
          return {
            ...editLeg,
            security,
            staticFields,
            tradeAssetType,
            profiles,
            tradeProfile,
            aliasError,
          }
        }
        return leg
      })
      setLegs(newLegs)
    } else {
      const leg = newLeg({
        security,
        staticFields,
        tradeAssetType,
        profiles,
        tradeProfile,
        aliasError,
      })
      setLegs([...legs, leg])
    }

    handleSecurityModalClose()
  }

  function handleSaveCashflowLeg(currencyRef: string) {
    clearErrorMessages()

    const tradeAssetType = assetTypes.find((assetType) => assetType.asset_type_tag === 'currency')
    const profiles = tradeProfiles.filter((tradeProfile) => tradeAssetType?.trade_profiles.includes(tradeProfile.tag))

    const currencyName = currencies[currencyRef]
    const tradeProfile: TradeProfile = 'cashflow'

    const security = {
      identifier: '',
      identifierType: '',
      assetRef: currencyRef,
      assetType: '',
      assetTypeName: 'Currency',
      assetName: currencyName || '',
    }

    if (editLeg) {
      const newLegs = legs.map((leg) => {
        if (leg.id === editLeg.id) {
          return {
            ...editLeg,
            security,
          }
        }
        return leg
      })
      setLegs(newLegs)
    } else {
      const leg = newLeg({
        security,
        staticFields: [],
        tradeAssetType,
        profiles,
        tradeProfile,
        aliasError: '',
      })
      setLegs([...legs, leg])
    }

    handleCashflowModalClose()
  }

  function newLeg(
    ops: Pick<Leg, 'security' | 'staticFields' | 'tradeAssetType' | 'profiles' | 'tradeProfile' | 'aliasError'>
  ): Leg {
    return {
      id: newId(),
      transactionRef: undefined,
      dealTransactions: undefined,
      security: ops.security,
      staticFields: ops.staticFields,
      tradeAssetType: ops.tradeAssetType,
      profiles: ops.profiles,
      aliasError: ops.aliasError,
      pricingError: '',
      tradeProfile: ops.tradeProfile,
      tradeDirection: null,
      commonParticulars: {
        venue: null,
        unique_txn_identifier: null,
        external_txn_ref: null,
        cci_number: null,
        executor: null,
      },
      value: '',
      price: '',
      numberOf: '',
      quantity: '',
      notional: '',
      spreadBps: '',
      accruedInterest: '',
      accruedExplainer: '',
      sinkFactor: '',
      haircut: '',
      inceptionFx: '',
      fundingAmountLc: '',
      repoFundingAmount: '',
      trsFundingAmount: '',
      quoteCurrency: '',
      spotRate: '',
      forwardRate: '',
      quoteAmount: '',
      settlementDate: null,
      settlementAmount: '',
      settlementPrice: '',
      actualSettlementDate: null,
      fixingDate: null,
      fixingPrice: '',
      fixingRate: '',
      isGiveUp: false,
      ndfRate: '',
      initialMarginRate: '',
      settleOnQuote: false,
      marginInQuoteCurrency: false,
      isFullyFunded: false,
      cashflowType: '',
      relatedAsset: '',
      upfrontCharge: '',
      upfrontExplainer: '',
      swapEffectiveDate: null,
      tranche: '',
    }
  }

  function handleAddLeg() {
    if (isCashflow) {
      cashflowModal.open()
    } else {
      securityModal.open()
    }
  }

  function handleEditLeg(leg: Leg) {
    setEditLeg(leg)
    if (leg.tradeProfile === 'cashflow') {
      cashflowModal.open()
    } else {
      securityModal.open()
    }
  }

  function handleSecurityModalClose() {
    setEditLeg(null)
    securityModal.close()
  }

  function handleCashflowModalClose() {
    setEditLeg(null)
    cashflowModal.close()
  }

  function handleUpdateLeg(updated: Leg) {
    setLegs(legs.map((leg) => (leg.id === updated.id ? updated : leg)))
  }

  function handleDeleteLeg(legId: number) {
    setLegs(legs.filter((leg) => leg.id !== legId))
  }

  function handleStrategyChange(_event: SyntheticEvent, value: string | null) {
    const newStrategy = value?.replace(/\W/g, '')
    setStrategy(newStrategy || '')
  }

  function handleBrokerChange(event: SelectChangeEvent) {
    setBrokerAccountId(event.target.value)
  }

  function handleNotesChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setNotes(event.target.value)
  }

  function clearErrorMessages() {
    consignNewTicket.reset()
    correctTransaction.reset()
    confirmDeals.reset()
    setErrorMessage('')
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    clearErrorMessages()

    if (isNewTicket || isCloseOutPosition) {
      handleConsignNewTicket()
    } else if (isTransactionCorrection) {
      handleCorrectTransaction()
    } else if (isDealConfirmation) {
      handleDealConfirmation()
    } else if (isCloseOutTransaction) {
      handleCloseTransaction()
    }
  }

  function handleConsignNewTicket() {
    if (!selectedPortfolioRef || !brokerAccountId || !executedAt) {
      return
    }

    if (!!originalTxnRef && !isSegmentRef(originalTxnRef)) {
      setErrorMessage(t('invalid_original_transaction'))
      return
    }

    executedAt.setSeconds(0)
    executedAt.setMilliseconds(0)

    const payload: ConsignNewTicketPayload = {
      deals: legs.map((leg) => {
        return {
          broker_account_id: Number(brokerAccountId),
          executed_at: executedAt.toISOString(),
          note: notes.trim() || null,
          legs: [getConsignmentDealLeg(leg)],
        } satisfies ConsignNewDeal
      }),
      allocations: {
        [selectedPortfolioRef]: 1,
      },
    }

    consignNewTicket.mutate(payload, {
      onSuccess: handleSubmitSuccess,
    })
  }

  function handleCorrectTransaction() {
    if (!selectedPortfolioRef || !transaction || !brokerAccountId || !executedAt) {
      return
    }

    if (!!originalTxnRef && !isSegmentRef(originalTxnRef)) {
      setErrorMessage(t('invalid_original_transaction'))
      return
    }

    executedAt.setSeconds(0)
    executedAt.setMilliseconds(0)

    const payload: CorrectTransactionPayload = {
      txn_datetime: executedAt.toISOString(),
      broker_account_id: Number(brokerAccountId),
      note: notes.trim() || null,
      legs: legs.map((leg) => getConsignmentDealLeg(leg)),
      allocations: {
        [selectedPortfolioRef]: 1,
      },
    }

    correctTransaction.mutate(
      {
        transactionRef: transaction.txn_ref,
        payload,
      },
      {
        onSuccess: handleSubmitSuccess,
      }
    )
  }

  function handleDealConfirmation() {
    if (!selectedPortfolioRef || !deal || !brokerAccountId || !executedAt) {
      return
    }

    if (!!originalTxnRef && !isSegmentRef(originalTxnRef)) {
      setErrorMessage(t('invalid_original_transaction'))
      return
    }

    executedAt.setSeconds(0)
    executedAt.setMilliseconds(0)

    const payload: ConfirmDealsPayload = {
      deals: [
        {
          deal_ref: deal.deal_ref,
          confirmed_at: executedAt.toISOString(),
          broker_account_id: Number(brokerAccountId),
          note: notes.trim() || null,
          legs: legs.map((leg) => getConsignmentDealLeg(leg)),
        },
      ],
      allocations: {
        [selectedPortfolioRef]: 1,
      },
    }

    confirmDeals.mutate(payload, {
      onSuccess: handleSubmitSuccess,
    })
  }

  function handleVerifyCloseOut() {
    const params = getCloseOutParams()
    if (!params) {
      return
    }

    closeOutTransactionVerify.mutate(params, {
      onSuccess: ({ data }) => {
        const newLegs = data.legs.map((closeLeg, index) => {
          const prevLeg = legs[index]!

          return {
            ...prevLeg,
            ...mapTradeParticularsToLeg(
              closeLeg.trade_profile,
              closeLeg.trade_particulars,
              closeLeg.consideration_asset_ref
            ),
          } satisfies Leg
        })

        setLegs(newLegs)
      },
    })
  }

  function handleCloseTransaction() {
    const params = getCloseOutParams()
    if (!params) {
      return
    }

    closeOutTransactionCommit.mutate(params, {
      onSuccess: handleSubmitSuccess,
    })
  }

  function getCloseOutParams() {
    if (!portfolio || !closeTransactionRef || !closeOutTransaction || !executedAt || legs.length !== 1) {
      return
    }

    const leg = legs[0]!

    executedAt.setSeconds(0)
    executedAt.setMilliseconds(0)

    const params: CloseOutParams = {
      lei: portfolio.lei,
      segmentRef: closeTransactionRef,
      body: {
        txn_datetime: executedAt.toISOString(),
        note: notes.trim() || null,
        trade_details: getTradeDetails(leg),
      },
    }

    return params
  }

  function getConsignmentDealLeg(leg: Leg): ConsignmentDealSegment {
    let security: TradeSecurity
    if (leg.security.assetRef) {
      security = {
        by_asset_ref: leg.security.assetRef,
      }
    } else {
      security = {
        create_new_custom: {
          asset_type: leg.security.assetType,
          short_name: leg.security.assetName,
        },
      }
    }

    const staticFields = leg.staticFields.map((staticField) => {
      return {
        datapoint_ref: staticField.datapointRef,
        value: staticField.value,
      }
    })

    return {
      security,
      static_fields: staticFields,
      trade_details: getTradeDetails(leg),
      strategy: strategy || null,
      common_particulars: leg.commonParticulars,
      original_segment: originalTxnRef || null,
    }
  }

  function getTradeDetails(leg: Leg): TradeDetails {
    const quantityMultiplier = leg.tradeDirection === 'sell' ? -1 : 1

    let trade: TradeDetails

    if (leg.tradeProfile === 'standard_bond') {
      trade = {
        standard_bond: {
          notional: Number(leg.notional) * quantityMultiplier,
          clean_price: Number(leg.price),
          accrued_interest: Number(leg.accruedInterest),
          sink_factor: leg.sinkFactor ? Number(leg.sinkFactor) / 100 : undefined,
          settlement_date: getDateFromLeg(leg, 'settlementDate'),
        },
      }
    } else if (leg.tradeProfile === 'open_bond_by_spread') {
      trade = {
        open_bond_by_spread: {
          consideration: Number(leg.value) * quantityMultiplier * -1,
          spread_bps: Number(leg.spreadBps),
          settlement_date: getDateFromLeg(leg, 'settlementDate'),
        },
      }
    } else if (leg.tradeProfile === 'close_bond_by_spread') {
      trade = {
        close_bond_by_spread: {
          notional: Number(leg.notional) * quantityMultiplier,
          spread_bps: Number(leg.spreadBps),
          settlement_date: getDateFromLeg(leg, 'settlementDate'),
        },
      }
    } else if (leg.tradeProfile === 'future') {
      trade = {
        future: {
          quantity: Number(leg.numberOf) * quantityMultiplier,
          price: Number(leg.price),
        },
      }
    } else if (leg.tradeProfile === 'standard_repo') {
      trade = {
        standard_repo: {
          notional: Number(leg.notional) * quantityMultiplier,
          clean_price: Number(leg.price),
          accrued_interest: Number(leg.accruedInterest),
          haircut: Number(leg.haircut) / 100,
          sink_factor: leg.sinkFactor ? Number(leg.sinkFactor) / 100 : undefined,
          inception_fx: Number(leg.inceptionFx),
          settlement_date: getDateFromLeg(leg, 'settlementDate'),
        },
      }
    } else if (leg.tradeProfile === 'fx_spot') {
      trade = {
        fx_spot: {
          notional: Number(leg.notional) * quantityMultiplier,
          spot_rate: Number(leg.spotRate),
          quote_currency: leg.quoteCurrency,
          settlement_date: getDateFromLeg(leg, 'settlementDate'),
          is_give_up: leg.isGiveUp,
        },
      }
    } else if (leg.tradeProfile === 'fx_forward') {
      trade = {
        fx_forward: {
          notional: Number(leg.notional) * quantityMultiplier,
          forward_rate: Number(leg.forwardRate),
          settlement_date: getDateFromLeg(leg, 'settlementDate'),
          initial_margin_rate: leg.initialMarginRate ? Number(leg.initialMarginRate) / 100 : undefined,
          is_give_up: leg.isGiveUp,
        },
      }
    } else if (leg.tradeProfile === 'ndf') {
      trade = {
        ndf: {
          notional: Number(leg.notional) * quantityMultiplier,
          ndf_rate: Number(leg.ndfRate),
          settle_on_quote: leg.settleOnQuote,
          fixing_date: getDateFromLeg(leg, 'fixingDate'),
          settlement_date: getDateFromLeg(leg, 'settlementDate'),
          initial_margin_rate: leg.initialMarginRate ? Number(leg.initialMarginRate) / 100 : undefined,
          margin_in_quote_currency: leg.marginInQuoteCurrency,
          is_give_up: leg.isGiveUp,
        },
      }
    } else if (leg.tradeProfile === 'trs') {
      trade = {
        trs: {
          notional: Number(leg.notional) * quantityMultiplier,
          clean_price: Number(leg.price),
          accrued_interest: Number(leg.accruedInterest),
          haircut: Number(leg.haircut) / 100,
          sink_factor: leg.sinkFactor ? Number(leg.sinkFactor) / 100 : undefined,
          settlement_date: getDateFromLeg(leg, 'settlementDate'),
          is_fully_funded: leg.isFullyFunded,
          inception_fx: leg.inceptionFx ? Number(leg.inceptionFx) : undefined,
        },
      }
    } else if (leg.tradeProfile === 'cds' || leg.tradeProfile === 'cdx') {
      trade = {
        cds: {
          is_cds_index: leg.tradeProfile === 'cdx',
          notional: Number(leg.notional) * quantityMultiplier,
          price: leg.price ? Number(leg.price) : undefined,
          spread_bps: leg.spreadBps ? Number(leg.spreadBps) : undefined,
          accrued_interest: Number(leg.accruedInterest),
          settlement_date: getDateFromLeg(leg, 'settlementDate'),
          swap_effective_date: leg.swapEffectiveDate ? formatNaiveDate(leg.swapEffectiveDate) : undefined,
          initial_margin_rate: leg.initialMarginRate ? Number(leg.initialMarginRate) / 100 : undefined,
          tranche: leg.tranche || undefined,
        },
      }
    } else if (leg.tradeProfile === 'cashflow') {
      trade = {
        cashflow: {
          amount: Number(leg.quantity) * quantityMultiplier,
          cashflow_type: leg.cashflowType,
          related_asset: leg.relatedAsset || undefined,
          settlement_date: getDateFromLeg(leg, 'settlementDate'),
        },
      }
    } else if (leg.tradeProfile === 'close_repo') {
      trade = {
        close_repo: {
          settlement_date: getDateFromLeg(leg, 'actualSettlementDate'),
          settlement_amount: Number(leg.settlementAmount),
        },
      }
    } else if (leg.tradeProfile === 'close_fx_forward') {
      trade = {
        close_fx_forward: null,
      }
    } else if (leg.tradeProfile === 'close_ndf') {
      trade = {
        close_ndf: {
          fixing_rate: Number(leg.fixingRate),
        },
      }
    } else if (leg.tradeProfile === 'close_trs') {
      trade = {
        close_trs: {
          settlement_date: getDateFromLeg(leg, 'actualSettlementDate'),
          settlement_amount: Number(leg.settlementAmount),
          settlement_price: Number(leg.settlementPrice),
        },
      }
    } else {
      throw new Error(`Unsupported trade profile: ${leg.tradeProfile}`)
    }

    return trade
  }

  function handleSubmitSuccess() {
    setLegs([])
    setStrategy('')
    setBrokerAccountId('')
    setNotes('')
    setExecutedAt(null)
    onCloseView?.()
    tradeTicketQuery.refetch()
    queryClient.invalidateQueries(['portfolios'])
    queryClient.invalidateQueries(['transactions'])
  }

  function handleRemoveLegAssetStatic(legAsset: EditLegAsset) {
    const newLegs = legs.map((leg) => {
      const isNewAsset = !legAsset.assetRef
      const onlyLeg = isNewAsset && leg.id === legAsset.legId
      const allAssets = !isNewAsset && leg.security.assetRef === legAsset.assetRef

      if (onlyLeg || allAssets) {
        return {
          ...leg,
          staticFields: leg.staticFields.filter((staticField) => {
            return staticField.datapointRef !== legAsset.datapointRef
          }),
        }
      }

      return leg
    })
    setLegs(newLegs)
  }

  function handleSaveLegAssetStatic(params: EditAssetParams) {
    if (!editAsset) {
      return
    }

    // when value coming from edit asset modal is empty (`"None": null`),
    // remove leg's asset static instead.
    if (isEditDatapointValue(params.value)) {
      handleRemoveLegAssetStatic(editAsset)
      setEditAsset(undefined)
      return
    }

    const newLegs = legs.map((leg) => {
      const isNewAsset = !params.assetRef
      const onlyLeg = isNewAsset && editAsset.legId === leg.id
      const allAssets = !isNewAsset && params.assetRef === leg.security.assetRef

      if (onlyLeg || allAssets) {
        if (!isDatapointValue(params.value)) {
          // this shouldn't happen because of the isEditDatapointValue check above,
          // but that's not enough to make TypeScript happy.
          throw new Error('params.value is not a DatapointValue')
        }

        const newStaticField: LegField = {
          datapointRef: params.datapointRef,
          value: params.value,
        }
        const newLeg = {
          ...leg,
          staticFields: [...leg.staticFields],
        }

        const fieldIndex = leg.staticFields.findIndex((staticField) => {
          return staticField.datapointRef === params.datapointRef
        })

        if (fieldIndex === -1) {
          newLeg.staticFields.push(newStaticField)
        } else {
          newLeg.staticFields[fieldIndex] = newStaticField
        }

        return newLeg
      }

      return leg
    })

    setLegs(newLegs)
    setEditAsset(undefined)
  }

  function handleSaveAssetRefAsset(
    assetMap: TradeTicketResponse['indexes'] | TradeTicketResponse['ref_rates'] | TradeTicketResponse['regular_curves'],
    assetRef: string
  ) {
    if (!editAsset || !assetRef) {
      return
    }

    const newLegs = legs.map((leg) => {
      const isNewAsset = !editAsset.assetRef
      const onlyLeg = isNewAsset && editAsset.legId === leg.id
      const allAssets = !isNewAsset && editAsset.assetRef === leg.security.assetRef

      if (onlyLeg || allAssets) {
        const newStaticField: Leg['staticFields'][number] = {
          datapointRef: editAsset.datapointRef,
          value: {
            AssetRef: assetRef,
          },
          security: {
            assetName: assetMap[assetRef] || '',
            assetRef: assetRef,
            assetType: editAsset.datapointType,
            assetTypeName: editAsset.datapointName,
            identifier: '',
            identifierType: '',
          },
        }
        const newLeg = {
          ...leg,
          staticFields: [...leg.staticFields],
        }

        const fieldIndex = leg.staticFields.findIndex((staticField) => {
          return staticField.datapointRef === editAsset.datapointRef
        })

        if (fieldIndex === -1) {
          newLeg.staticFields.push(newStaticField)
        } else {
          newLeg.staticFields[fieldIndex] = newStaticField
        }

        return newLeg
      }

      return leg
    })

    setLegs(newLegs)
    setEditAsset(undefined)
  }

  function handleSaveSecurityAsset(legSecurity: Leg['security']) {
    if (!editAsset) {
      return
    }

    const newLegs = legs.map((leg) => {
      const isNewAsset = !editAsset.assetRef
      const onlyLeg = isNewAsset && editAsset.legId === leg.id
      const allAssets = !isNewAsset && editAsset.assetRef === leg.security.assetRef

      if (onlyLeg || allAssets) {
        const newStaticField: Leg['staticFields'][number] = {
          datapointRef: editAsset.datapointRef,
          value: {
            AssetRef: legSecurity.assetRef,
          },
          security: legSecurity,
        }
        const newLeg = {
          ...leg,
          staticFields: [...leg.staticFields],
        }

        const fieldIndex = leg.staticFields.findIndex((staticField) => {
          return staticField.datapointRef === editAsset.datapointRef
        })

        if (fieldIndex === -1) {
          newLeg.staticFields.push(newStaticField)
        } else {
          newLeg.staticFields[fieldIndex] = newStaticField
        }

        return newLeg
      }

      return leg
    })

    setLegs(newLegs)
    setEditAsset(undefined)
  }

  function handleSaveCurrencyAsset(currencyRef: string) {
    if (!editAsset) {
      return
    }

    const newLegs = legs.map((leg) => {
      const isNewAsset = !editAsset.assetRef
      const onlyLeg = isNewAsset && editAsset.legId === leg.id
      const allAssets = !isNewAsset && editAsset.assetRef === leg.security.assetRef

      if (onlyLeg || allAssets) {
        const newStaticField: Leg['staticFields'][number] = {
          datapointRef: editAsset.datapointRef,
          value: {
            AssetRef: currencyRef,
          },
          security: {
            assetName: currencies[currencyRef] || '',
            assetRef: currencyRef,
            assetType: editAsset.datapointType,
            assetTypeName: editAsset.datapointName,
            identifier: '',
            identifierType: '',
          },
        }
        const newLeg = {
          ...leg,
          staticFields: [...leg.staticFields],
        }

        const fieldIndex = leg.staticFields.findIndex((staticField) => {
          return staticField.datapointRef === editAsset.datapointRef
        })

        if (fieldIndex === -1) {
          newLeg.staticFields.push(newStaticField)
        } else {
          newLeg.staticFields[fieldIndex] = newStaticField
        }

        return newLeg
      }

      return leg
    })

    setLegs(newLegs)
    setEditAsset(undefined)
  }

  function handleEditAssetStaticClose() {
    setEditAsset(undefined)
  }

  if (showLoadingScreen) {
    return (
      <AppLayout>
        <Stack maxWidth="lg" alignSelf="center" alignItems="center" mt={4}>
          {!error && <CircularProgress size={24} />}
          <Alert severity="error" message={error} onClose={onCloseView} />
        </Stack>
      </AppLayout>
    )
  }

  return (
    <AppLayout>
      <Box
        sx={{
          overflow: 'auto',
          px: 2,
          height: '100%',
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            backgroundColor: 'gray.main',
            my: 4,
            pt: 4,
            pb: 2,
            borderRadius: 1,
            minWidth: '900px',
          }}
        >
          <form onSubmit={handleSubmit}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              {pageTitle}
            </Typography>

            <Alert severity="error" message={error} onClose={clearErrorMessages} sx={{ mb: 2 }} />

            <Stack sx={{ gap: 2, mb: 2 }}>
              {legs.map((leg, index) => (
                <SecurityLeg
                  key={leg.id}
                  index={index + 1}
                  leg={leg}
                  currencies={currencies}
                  cashflowTypes={cashflowTypes}
                  tradeTicket={tradeTicket}
                  users={users}
                  isTradeConfirmation={isDealConfirmation}
                  isCloseOutPosition={isCloseOutPosition}
                  isCloseOutTransaction={isCloseOutTransaction}
                  onEdit={canEditLeg ? handleEditLeg : undefined}
                  onUpdate={handleUpdateLeg}
                  onDelete={canDeleteLeg ? handleDeleteLeg : undefined}
                  onEditAsset={setEditAsset}
                  onRemoveAsset={handleRemoveLegAssetStatic}
                  onVerifyCloseOut={handleVerifyCloseOut}
                />
              ))}
            </Stack>

            {canAddLeg && (
              <Button startIcon={<Add />} onClick={handleAddLeg}>
                {t('add_leg')}
              </Button>
            )}

            <Divider sx={{ my: 4 }} />

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: showOriginalTxn ? 'repeat(5, 1fr)' : 'repeat(4, 1fr)',
                gridTemplateRows: 'auto',
                gap: 2,
              }}
            >
              <Autocomplete
                freeSolo
                forcePopupIcon
                disabled={isCloseOutTransaction}
                value={strategy}
                inputValue={strategy}
                options={strategies}
                onChange={handleStrategyChange}
                onInputChange={handleStrategyChange}
                renderInput={(props) => <TextField {...props} label={t('strategy')} />}
                popupIcon={<Search sx={{ color: 'gray.300' }} />}
                componentsProps={{
                  popupIndicator: {
                    disableRipple: true,
                    sx: { transform: 'none' },
                  },
                }}
              />

              <FormControl required={!isCloseOutTransaction} disabled={isCloseOutTransaction}>
                <InputLabel>{t('counterparty')}</InputLabel>
                <Select name="broker" label={t('counterparty')} value={brokerAccountId} onChange={handleBrokerChange}>
                  {brokers.map((broker) => (
                    <MenuItem key={broker.broker_id} value={broker.broker_id}>
                      {broker.broker_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl required>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label={executedAtLabel}
                    value={executedAt}
                    inputFormat={datetimeFormat}
                    maxDate={executedAtDateLimit}
                    onChange={setExecutedAt}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        error={false}
                        autoComplete="off"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: datetimePlaceholder,
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>

              {showOriginalTxn && (
                <FormControl>
                  <TextField
                    name="originalTxnRef"
                    label={t('original_transaction')}
                    value={originalTxnRef}
                    autoComplete="off"
                    onChange={(event) => setOriginalTxnRef(event.target.value)}
                  />
                </FormControl>
              )}

              <FormControl required disabled={!canChangePortfolio}>
                <InputLabel>{t('common:portfolio')}</InputLabel>
                <Select
                  name="portfolio"
                  label={t('common:portfolio')}
                  value={!!portfolios.length ? selectedPortfolioRef : ''}
                  onChange={(event) => setSelectedPortfolioRef(event.target.value)}
                >
                  {portfolios.map((portfolio) => (
                    <MenuItem key={portfolio.portfolio_ref} value={portfolio.portfolio_ref}>
                      {portfolio.portfolio_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <TextField
              label={t('notes')}
              value={notes}
              multiline
              minRows={1}
              maxRows={10}
              fullWidth
              onChange={handleNotesChange}
              sx={{ mt: 4 }}
            />

            <Divider sx={{ mt: 8, mb: 2, mx: '-24px' }} />

            <DialogActions sx={{ flexDirection: 'row-reverse', justifyContent: 'flex-start', gap: 1, px: 0 }}>
              <Button type="submit" variant="contained" disabled={submitDisabled}>
                {isSubmitting && <CircularProgress size={24} color="inherit" sx={{ position: 'absolute' }} />}
                <Typography color="inherit" variant="inherit" sx={{ opacity: isSubmitting ? 0 : 1 }}>
                  {submitLabel}
                </Typography>
              </Button>
              {!!onCloseView && <Button onClick={onCloseView}>{t('common:cancel')}</Button>}
            </DialogActions>
          </form>
        </Container>
      </Box>

      <SelectSecurityModal
        open={securityModal.isOpen}
        security={editLeg?.security}
        tradeTicket={tradeTicket}
        onClose={handleSecurityModalClose}
        onSave={handleSaveSecurityLeg}
      />
      <EditAssetStaticModal
        open={!!editAsset && !editAsset.assetCategory && editAsset.datapointType !== 'AssetRef'}
        assetRef={editAsset?.assetRef}
        assetDescription={editAsset?.assetDescription || ''}
        datapointRef={editAsset?.datapointRef}
        datapointType={editAsset?.datapointType}
        datapointName={editAsset?.datapointName || ''}
        datapointValue={editAsset?.datapointValue}
        classificationId={editAsset?.classificationId}
        onSave={handleSaveLegAssetStatic}
        onClose={handleEditAssetStaticClose}
        datasetRef={datasetRef}
      />
      <SelectItemModal
        required
        title={t('dataEngine:index_modal.title')}
        inputLabel={t('dataEngine:index_modal.input_label')}
        open={!!editAsset && editAsset.assetCategory === 'index' && editAsset.datapointType === 'AssetRef'}
        selectedId={(editAsset?.datapointValue as any)?.['AssetRef']}
        options={Object.entries(indexes).map(([assetRef, label]) => ({ id: assetRef, label }))}
        onSave={async (assetRef) => handleSaveAssetRefAsset(indexes, assetRef)}
        onClose={handleEditAssetStaticClose}
      />
      <SelectItemModal
        required
        title={t('dataEngine:ref_rate_modal.title')}
        inputLabel={t('dataEngine:ref_rate_modal.input_label')}
        open={!!editAsset && editAsset.assetCategory === 'ref_rate' && editAsset.datapointType === 'AssetRef'}
        selectedId={(editAsset?.datapointValue as any)?.['AssetRef']}
        options={Object.entries(refRates).map(([assetRef, label]) => ({ id: assetRef, label }))}
        onSave={async (assetRef) => handleSaveAssetRefAsset(refRates, assetRef)}
        onClose={handleEditAssetStaticClose}
      />
      <SelectItemModal
        required
        title={t('dataEngine:regular_curve_modal.title')}
        inputLabel={t('dataEngine:regular_curve.input_label')}
        open={!!editAsset && editAsset.assetCategory === 'curve' && editAsset.datapointType === 'AssetRef'}
        selectedId={(editAsset?.datapointValue as any)?.['AssetRef']}
        options={Object.entries(regularCurves).map(([assetRef, label]) => ({ id: assetRef, label }))}
        onSave={async (assetRef) => handleSaveAssetRefAsset(regularCurves, assetRef)}
        onClose={handleEditAssetStaticClose}
      />
      <SelectSecurityModal
        open={!!editAsset && editAsset.assetCategory === 'security' && editAsset.datapointType === 'AssetRef'}
        security={editAssetSecurity}
        onlyNoIdentifier
        tradeTicket={tradeTicket}
        onClose={handleEditAssetStaticClose}
        onSave={handleSaveSecurityAsset}
      />
      <SelectCurrencyModal
        open={!!editAsset && editAsset.assetCategory === 'currency'}
        currencyRef={editAssetSecurity?.assetRef}
        currencies={currencies}
        onSave={handleSaveCurrencyAsset}
        onClose={handleEditAssetStaticClose}
      />
      <SelectCurrencyModal
        open={cashflowModal.isOpen}
        currencyRef={editLeg?.security.assetRef}
        currencies={currencies}
        onSave={handleSaveCashflowLeg}
        onClose={handleCashflowModalClose}
      />
    </AppLayout>
  )
}

export default TradeTicketRoot

function getDateFromLeg(leg: Leg, field: keyof Pick<Leg, 'settlementDate' | 'actualSettlementDate' | 'fixingDate'>) {
  const date = leg[field]
  if (!date) {
    throw new Error(`Missing ${field} in leg`)
  }
  return formatNaiveDate(date)
}

export function mapTradeParticularsToLeg(
  tradeProfile: TradeProfile,
  tradeParticulars: TradeParticulars,
  quoteCurrency: string | null
) {
  const isStandardBond = tradeProfile === 'standard_bond'
  const isIndexFuture = tradeProfile === 'future'
  const isStandardRepo = tradeProfile === 'standard_repo'
  const isTrs = tradeProfile === 'trs'
  const isCloseRepo = tradeProfile === 'close_repo'
  const isCloseTrs = tradeProfile === 'close_trs'
  const isCds = tradeProfile === 'cds'
  const isCdx = tradeProfile === 'cdx'
  const isCdsx = isCds || isCdx

  return {
    numberOf: parseTPValue(tradeParticulars.number_of),
    quantity: parseTPValue(tradeParticulars.quantity),
    notional: parseTPValue(tradeParticulars.notional),
    value: parseTPValue(tradeParticulars.consideration, isCdsx),
    price:
      isStandardBond || isStandardRepo || isTrs || isCloseRepo || isCloseTrs
        ? parseTPValue(tradeParticulars.clean_price)
        : isIndexFuture
          ? parseTPValue(tradeParticulars.contract_price)
          : // CDS/CDX can have price or contract_price
            parseTPValue(tradeParticulars.price) || parseTPValue(tradeParticulars.contract_price),
    // CDS/CDX can have spread_bps or contract_spread
    spreadBps: parseTPValue(tradeParticulars.spread_bps) || parseTPValue(tradeParticulars.contract_spread),
    accruedInterest: parseTPValue(tradeParticulars.accrued_interest, isCdsx),
    accruedExplainer: tradeParticulars.accrued_explainer || '',
    sinkFactor: parseTPPercentageValue(tradeParticulars.sink_factor),
    haircut: parseTPPercentageValue(tradeParticulars.haircut),
    inceptionFx: parseTPValue(tradeParticulars.inception_fx),
    fundingAmountLc: parseTPValue(tradeParticulars.funding_amount_lc),
    repoFundingAmount: parseTPValue(tradeParticulars.repo_funding_amount),
    trsFundingAmount: parseTPValue(tradeParticulars.trs_funding_amount),
    quoteCurrency: quoteCurrency || '',
    spotRate: parseTPValue(tradeParticulars.spot_rate),
    forwardRate: parseTPValue(tradeParticulars.forward_rate),
    quoteAmount: parseTPValue(tradeParticulars.quote_amount),
    settlementDate: parseNaiveDate(tradeParticulars.settlement_date),
    settlementAmount: parseTPValue(tradeParticulars.settlement_amount, true),
    settlementPrice: parseTPValue(tradeParticulars.settlement_price),
    actualSettlementDate: parseNaiveDate(tradeParticulars.actual_settlement_date),
    fixingDate: parseNaiveDate(tradeParticulars.fixing_date),
    fixingPrice: parseTPValue(tradeParticulars.fixing_price),
    fixingRate: parseTPValue(tradeParticulars.fixing_rate),
    isGiveUp: !!tradeParticulars.is_give_up,
    ndfRate: parseTPValue(tradeParticulars.ndf_rate),
    initialMarginRate: parseTPPercentageValue(tradeParticulars.initial_margin_rate),
    settleOnQuote: !!tradeParticulars.settle_on_quote,
    marginInQuoteCurrency: !!tradeParticulars.margin_in_quote_currency,
    isFullyFunded: !!tradeParticulars.is_fully_funded,
    cashflowType: tradeParticulars.cashflow_type || '',
    relatedAsset: tradeParticulars.related_asset || '',
    upfrontCharge: parseTPValue(tradeParticulars.upfront_charge, isCdsx),
    upfrontExplainer: tradeParticulars.upfront_explainer || '',
    swapEffectiveDate: parseNaiveDate(tradeParticulars.swap_effective_date),
    tranche: tradeParticulars.tranche || '',
  } satisfies TradeParticularsFields
}

const segmentRefRegex = new RegExp('^T[A-Za-z0-9]{10}-[0-9]$')

function isSegmentRef(segmentRef: string): boolean {
  return segmentRefRegex.test(segmentRef)
}
